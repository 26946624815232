import React, { useMemo, useState, useEffect } from "react";
import { Box } from "@mui/system";
import { DataTable } from "../shared/data_table/DataTable";
import ReportServices from "../../services/reportServices";
import { CardHeader, CardContent, Chip } from "@mui/material";
import { Breadcrumb } from "../shared/Breadcrumbs";
import { useTheme } from "@mui/styles";
import MouseOverPopover from "../shared/PopOver";
import VDRDropDown from "./VDRDropDown";
import { ButtonAlign } from "../shared/button/ButtonAlign";
import { Button } from "@mui/material";
import logServices from "../../services/logService";
import {
    StyledCardShadow,
    Exportoptions,
    StyledPageLayout,
    StyledTableTitle,
} from "../../theme/StyledEle";
import addDays from "date-fns/addDays";
import { format } from "date-fns";
import FilterDropdown from "../NewDropdown/FilterDropdown";
import { useVehicleDataMontra3wMutation } from "../../store/slice/ReportSlice/ReportSlice";
import { useAuditLogMutation } from "../../store/slice/ApiSlice";


const ManualCanDataReport3WMontra = () => {

    let userData = JSON.parse(localStorage.getItem("userData"));
    let resellerId = userData.resellerId;
    let orgId = userData.orgId;
    let userId = userData.id;
    let orgName = userData.orgName;
    let userName = userData.userName;


    const [responseData, setResponseData] = useState([]);
    const text = "";

    const [auditLog] = useAuditLogMutation();
    let auditPayload = {
        //Audit Common Payload
        orgId: orgId,
        userId: userId, //User ID
        orgName: orgName, //Org Name
        userName: userName,
    }

    useEffect(async () => {
        auditPayload.message = 'Reports > VehicleData';
        auditPayload.action = 'View';
        auditPayload.featureName = 'VehicleData';
        auditPayload.customerName = 'All';
        auditPayload.fleetName = "All";
        auditPayload.result = 'Success';
        auditPayload.access = 'Portal';
        auditPayload.severity = 'INFO';
        auditLog(auditPayload);

    }, []);

    const columns = useMemo(
        () => [
            {
                Header: "Date",
                accessor: "createdDate",
            },
            {
                Header: "Received Date",
                accessor: "receivedDate",
                width: 180
            },
            {
                Header: "Vehicle Number",
                accessor: "vehicleNo",
                width: 180
            },
            {
                Header: "Vehicle Status",
                accessor: "gpsStatus",
                width: 120,
                Cell: ({ row }) => {
                    // console.log('row', row.original.strgpsMoveStatus);
                    if (row.original.gpsStatus !== '-') {
                        return <Chip label={row.original.gpsStatus} size="small" color={row.original.gpsStatus === 'Moving' ? 'success' : row.original.gpsStatus === 'Charging' ? 'warning' : row.original.gpsStatus === 'Idle' ? 'primary' : 'error'} />
                    } else {
                        return row.original.gpsStatus
                    }
                }
            },
            {
                Header: "GPS Location",
                accessor: "gpsLocation",
                width: 350,
                Cell: ({ row, column, cell }) => {
                    const refAddres = React.useRef(row);
                    var refLink = React.useRef(row);
                    var getAddressBtn = React.useRef(row);
                    var toggleAddressBtn = React.useRef(row);
                    const getAddress = async (e) => {
                        if (refAddres.current.innerText === '') {
                            var request = {};
                            request.latLng = e.target.value;
                            // rowid = e.target.id;
                            const responseData = (await ReportServices.getGeocodeAddress(request)).data;
                            refAddres.current.innerText = responseData;
                            if (responseData !== '') {
                                refLink.current.style.display = 'none'
                                refAddres.current.style.display = 'block'
                                toggleAddressBtn.current.style.display = 'block'
                                getAddressBtn.current.style.display = 'none'
                                toggleAddressBtn.current.innerText = 'LatLng'
                            }
                        }
                    }

                    //Address toggle
                    const ToggleAdderss = () => {
                        if (refAddres.current.style.display === 'block') {
                            refAddres.current.style.display = 'none'
                            refLink.current.style.display = 'block'
                            toggleAddressBtn.current.innerText = 'Address'
                        } else {
                            refAddres.current.style.display = 'block'
                            refLink.current.style.display = 'none'
                            toggleAddressBtn.current.innerText = 'LatLng'
                        }
                    }
                    return (
                        <Box sx={{ display: 'flex', alignItems: 'center' }} >
                            <Box className='addresslink' id={row.original.gpsLocation} sx={{ display: 'flex', alignItems: 'center' }}>
                                <Button onClick={(e) => getAddress(e)} value={row.original.gpsLocation} ref={getAddressBtn}>Address</Button>
                                <Button onClick={(e) => ToggleAdderss(e)} ref={toggleAddressBtn} sx={{ display: 'none' }}>LatLng</Button>
                                <a href={`https://www.google.com/maps/search/?api=1&query=${row.original.gpsLocation}`} target="_blank" className="latlink" ref={refLink} id={row.original.gpsLocation}>{row.original.gpsLocation}</a>
                                <a href={`https://www.google.com/maps/search/?api=1&query=${row.original.gpsLocation}`} target="_blank" className="address" ref={refAddres} id={row.original.gpsLocation} key={row.original.gpsLocation}></a>
                            </Box>
                        </Box>
                    )
                },
            },
            {
                Header: "Odometer (km)",
                accessor: "odometer",
                width: 120,
            },
            {
                Header: "Speed (km/h)",
                accessor: "speed",
                width: 120,
            },
            {
                Header: "VCU Type",
                accessor: "vcuType",
                width: 120,
            },
            {
                Header: "Sequence Id",
                accessor: "packetSequenceId",
                width: 120,
            },
            {
                Header: "Bmd Id",
                accessor: "bmsId",
                width: 120,
            },
            {
                Header: "Mc Id",
                accessor: "mcId",
                width: 120,
            },
            {
                Header: "Current(A)",
                accessor: "current",
                width: 120,
            },
            {
                Header: "SOC(%)",
                accessor: "soc",
                width: 120,
            },
            {
                Header: "Max Cell Voltage (V)",
                accessor: "maxCellVoltage",
                width: 120,
            },
            {
                Header: "Max Cell Id",
                accessor: "maxCellId",
                width: 120,
            },
            {
                Header: "min Cell Voltage (V)",
                accessor: "minCellVoltage",
                width: 120,
            },
            {
                Header: "Min Cell Id",
                accessor: "minCellId",
                width: 120,
            }, 
            {
                Header: "Charge Status",
                accessor: "chargeStatus",
                width: 120,
            },
            {
                Header: "Cumulative Power",
                accessor: "chargeMos",
                width: 120,
            },
            {
                Header: "Discharge Mos",
                accessor: "dischargeMos",
                width: 120,
            },
            {
                Header: "Residual Capacity",
                accessor: "resCapacity",
                width: 120,
            },
            {
                Header: "Temperature (℃)",
                accessor: "temperature",
                width: 120,
            },
            {
                Header: "No of Cells",
                accessor: "noOfCells",
                width: 120,
            },
            {
                Header: "SOH",
                accessor: "loadStatus",
                width: 120,
            },
            {
                Header: "Charge Cycle",
                accessor: "chargeCycle",
                width: 120,
            },
            {
                Header: "Cell Voltage 1 (V)",
                accessor: "cellVolt1",
                width: 120,
            },
            {
                Header: "Cell Voltage 2 (V)",
                accessor: "cellVolt2",
                width: 120,
            },
            {
                Header: "Cell Voltage 3 (V)",
                accessor: "cellVolt3",
                width: 120,
            },
            {
                Header: "Cell Voltage 4 (V)",
                accessor: "cellVolt4",
                width: 120,
            },
            {
                Header: "Cell Voltage 5 (V)",
                accessor: "cellVolt5",
                width: 120,
            },
            {
                Header: "Cell Voltage 6 (V)",
                accessor: "cellVolt6",
                width: 120,
            },
            {
                Header: "Cell Voltage 7 (V)",
                accessor: "cellVolt7",
                width: 120,
            },
            {
                Header: "Cell Voltage 8 (V)",
                accessor: "cellVolt8",
                width: 120,
            },
            {
                Header: "Cell Voltage 9 (V)",
                accessor: "cellVolt9",
                width: 120,
            },
            {
                Header: "Cell Voltage 10 (V)",
                accessor: "cellVolt10",
                width: 120,
            },
            {
                Header: "Cell Voltage 11 (V)",
                accessor: "cellVolt11",
                width: 120,
            },
            {
                Header: "Cell Voltage 12 (V)",
                accessor: "cellVolt12",
                width: 120,
            },
            {
                Header: "Cell Voltage 13 (V)",
                accessor: "cellVolt13",
                width: 120,
            },
            {
                Header: "Cell Voltage 14 (V)",
                accessor: "cellVolt14",
                width: 120,
            },
            {
                Header: "Cell Voltage 15 (V)",
                accessor: "cellVolt15",
                width: 120,
            },
            {
                Header: "Cell Voltage 16 (V)",
                accessor: "cellVolt16",
                width: 120,
            },
            {
                Header: "Battery Pack Voltage",
                accessor: "batteryPackVoltage",
                width: 120,
            },
            {
                Header: "Running Current",
                accessor: "runningCurrent",
                width: 120,
            },
            {
                Header: "Current Proportional",
                accessor: "currentProportional",
                width: 120,
            },
            {
                Header: "Velocity",
                accessor: "velocity",
                width: 120,
            },
            {
                Header: "Mileage",
                accessor: "mileage",
                width: 120,
            },
            {
                Header: "Acceleration 1",
                accessor: "acceleration1",
                width: 120,
            },
            {
                Header: "Acceleration 2",
                accessor: "acceleration2",
                width: 120,
            },
            {
                Header: "Acceleration 3",
                accessor: "acceleration3",
                width: 120,
            },
            {
                Header: "Gyro 1",
                accessor: "gyro1",
                width: 120,
            },
            {
                Header: "Gyro 2",
                accessor: "gyro2",
                width: 120,
            },
            {
                Header: "Gyro 3",
                accessor: "gyro3",
                width: 120,
            },
            {
                Header: "Compass",
                accessor: "compass",
                width: 120,
            },
            {
                Header: "Internal Battery Volt",
                accessor: "internalBatVolt",
                width: 120,
            },
            {
                Header: "Signal Strength",
                accessor: "signalStrength",
                width: 120,
            },
            {
                Header: "Ignition Status",
                accessor: "ignitionStatus",
                width: 120,
            },
            {
                Header: "Range Covered",
                accessor: "rangeCovered",
                width: 120,
            },
            {
                Header: "Max Speed",
                accessor: "maxSpeed",
                width: 120,
            },
            {
                Header: "Ride Mode",
                accessor: "rideMode",
                width: 120,
            },
            {
                Header: "Altitude",
                accessor: "altitude",
                width: 120,
            },
            {
                Header: "Heading",
                accessor: "heading",
                width: 120,
            },
            {
                Header: "Gps Speed",
                accessor: "gpsSpeed",
                width: 120,
            },
            {
                Header: "Satellite Count",
                accessor: "satellitesCount",
                width: 120,
            },
            {
                Header: "Total Temp Sensor",
                accessor: "totalTempSensor",
                width: 120,
            },
            {
                Header: "Cell Temperature 1",
                accessor: "cellTemperature1",
                width: 120,
            },
            {
                Header: "Cell Temperature 2",
                accessor: "cellTemperature2",
                width: 120,
            },
            {
                Header: "Cell Temperature 3",
                accessor: "cellTemperature3",
                width: 120,
            },
            {
                Header: "Cell Temperature 4",
                accessor: "cellTemperature4",
                width: 120,
            },
            {
                Header: "Cell Temperature 5",
                accessor: "cellTemperature5",
                width: 120,
            },
            {
                Header: "Cell Temperature 6",
                accessor: "cellTemperature6",
                width: 120,
            },
            {
                Header: "Cell Temperature 7",
                accessor: "cellTemperature7",
                width: 120,
            },
            {
                Header: "Cell Temperature 8",
                accessor: "cellTemperature8",
                width: 120,
            },
            {
                Header: "Cell Temperature 9",
                accessor: "cellTemperature9",
                width: 120,
            },
            {
                Header: "Max Temp Id",
                accessor: "maxTempID",
                width: 120,
            },
            {
                Header: "Min Temp Id",
                accessor: "minTempID",
                width: 120,
            },
            {
                Header: "Alarm Code",
                accessor: "alarmCode",
                width: 120,
            },
            {
                Header: "Alarm Value",
                accessor: "alarmValue",
                width: 120,
            },
            {
                Header: "Occured Time",
                accessor: "alarmOccuredTime",
                width: 120,
            },
            {
                Header: "Cleared Time",
                accessor: "alarmClearedTime",
                width: 120,
            },
            {
                Header: "Alarm Sensor Value",
                accessor: "alarmSensorValue",
                width: 120,
            },
            {
                Header: "Alarm Limit Value",
                accessor: "alarmLimitValue",
                width: 120,
            },{
                Header: "Motor Temperature",
                accessor: "motorTemp",
                width: 120,
            },{
                Header: "MCU Temperature",
                accessor: "mcuTemp",
                width: 120,
            },{
                Header: "Full Battery Capacity Static",
                accessor: "fullBatteryCapacityStatic",
                width: 120,
            },{
                Header: "BMS Fw Version",
                accessor: "bmsFwVersion",
                width: 120,
            },{
                Header: "Vehicle Type",
                accessor: "vehicleType",
                width: 120,
            },{
                Header: "Pre Charge Temperature",
                accessor: "preChargeTemp",
                width: 120,
            },{
                Header: "MCU Fw Version",
                accessor: "mcuFwVersion",
                width: 120,
            },{
                Header: "AC Current Rms U",
                accessor: "acCurrentRmsU",
                width: 120,
            },{
                Header: "AC Current Rms V",
                accessor: "acCurrentRmsV",
                width: 120,
            },{
                Header: "AC Current Rms W",
                accessor: "acCurrentRmsW",
                width: 120,
            },{
                Header: "MCUSn",
                accessor: "mcuSn",
                width: 120,
            },{
                Header: "TrqSt",
                accessor: "trqSt",
                width: 120,
            },{
                Header: "DC Volt",
                accessor: "dcVolt",
                width: 120,
            },{
                Header: "DC Current",
                accessor: "dcCurrent",
                width: 120,
            },{
                Header: "Hill Hold",
                accessor: "hillHold",
                width: 120,
            },{
                Header: "Limp Home",
                accessor: "limpHome",
                width: 120,
            },{
                Header: "BMS Fw date",
                accessor: "bmsFwdate",
                width: 120,
            },{
                Header: "Cummulative Energy Dynamic",
                accessor: "cumEnergyDyn",
                width: 120,
            },{
                Header: "Cummulative Energy Static",
                accessor: "cumEnergyStatic",
                width: 120,
            },{
                Header: "Pre Charge Mosfet Status",
                accessor: "preChargeMosfetStatus",
                width: 120,
            },{
                Header: "BMS Mosfet Temp",
                accessor: "bmsMosfetTemp",
                width: 120,
            }
        ],
        []
    );

    const [newPage, setNewPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [totalElements, setTotalElements] = useState(5);
    const [totalPages, setTotalPages] = useState(1);

    
  const [vehicleDataMontra, {data:montraVehicleData, isLoading, isSuccess}] = useVehicleDataMontra3wMutation();
  const [payload, setPayload] = useState();
  
  let commonPayload = {
    'resellerId': resellerId,
  }

  const onSubmitForm = (data) => {
    setNewPage(0);
    setRowsPerPage(50);
    commonPayload.regionId = data.regionId;
    commonPayload.dealerId = data.dealerId;
    commonPayload.customerId = data.customerId;
    commonPayload.orgId = data.fleetId;
    commonPayload.vehicleId = data.vehicleId;
    commonPayload.startDate = data.fromDate;
    commonPayload.endDate = data.toDate;
    commonPayload.orderDir = 'desc';
    commonPayload.start = newPage;
    commonPayload.length = rowsPerPage;
    commonPayload.status = data.vehicleStatusId;
    commonPayload.search = '';
    setPayload(commonPayload);
    vehicleDataMontra(commonPayload);

    auditPayload.message = "Reports > Vehicle Data > "+data.regionName+" > "+data.dealerName+" > "+data.customerName+" > "+data.fleetName+" > "+data.vehicleName+" > "+data.statusName+" > "+data.selectedPeriodName+"("+data.fromDate+"-"+data.toDate+")"+" > "+"Submit";
    auditPayload.action = "Submit";
    auditPayload.featureName = "Vehicle Data Report";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
  }

  //Search Text
  const handleSearch = async (searchText) => {
    payload.search = searchText;
      payload.size = 0;
      payload.rowsPerPage = 50;
      setNewPage(0);
      setRowsPerPage(50);
    if(payload.search.length >= 3){
      vehicleDataMontra(payload);
    }else if(payload.search.length === 0){
      vehicleDataMontra(payload);
    }
  }


    const onChangePdf = async () => {
    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "ManualCanDataReport/vehicledataPDF.pdf?startDate=" +
        payload.startDate +
        "&endDate=" +
        payload.endDate +
        "&orderDir=" +
        payload.orderDir +
        "&orgId=" +
        payload.orgId +
        "&status=" +
        payload.status +
        "&vehicleId=" +
        payload.vehicleId;
        window.open(url);

        auditPayload.message = "Reports > Vehicle Data > Export > PDF";
        auditPayload.action = "View";
        auditPayload.featureName = "VDR Report";
        auditPayload.customerName = "All";
        auditPayload.fleetName = "All";
        auditPayload.result = "Success";
        auditPayload.access = "Portal";
        auditPayload.severity = "INFO";
        auditLog(auditPayload);

    } catch (error) {
      console.log('error', error);
    }
  };

  const onChangeXcl = async () => {
    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "ManualCanDataReport/vehicleDataReportForMontraThreeWheelercsv?startDate=" +
        payload.startDate +
        "&endDate=" +
        payload.endDate +
        "&orderDir=" +
        payload.orderDir +
        "&orgId=" +
        payload.orgId +
        "&status=" +
        payload.status +
        "&vehicleId=" +
        payload.vehicleId;

      window.open(url);

      auditPayload.message = "Reports > Vehicle Data > Export > Excel";
      auditPayload.action = "View";
      auditPayload.featureName = "VDR Report";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditLog(auditPayload);
    } catch (error) {
      console.log('error', error)
    }
  };

   //Page Change
   const pageChange = (newPage, rowsPerPage) => {
    setNewPage(newPage);
    setRowsPerPage(rowsPerPage);
    payload.page = newPage;
    payload.size = rowsPerPage;
    payload.start=newPage;
    payload.length=rowsPerPage;
    vehicleDataMontra(payload);
  } 

  useEffect(() => {
    if(isSuccess){
      // setResponseData([]);

      if(montraVehicleData.data !== 'null'){
        const vrdData = JSON.parse(montraVehicleData.data);
        setResponseData(vrdData.content);
        setTotalElements(vrdData.totalElements);
        setTotalPages(vrdData.totalPages);
      }


    }
  },[isSuccess]);

  return (
    <StyledPageLayout>
      <Breadcrumb mainDiv="Reports" subDiv="Vehicle Data Report" includePopup={true}></Breadcrumb>
      <MouseOverPopover text={text} title={"Vehicle Data Report"} />
      <Box>
        {}
         <FilterDropdown getData={onSubmitForm} time={true} isVehicleData={true} isShowAll={false} allowDays = {1} showTime = {true} allowedMonth = {resellerId === 110224 ? 6 : 3} last6Month={true} last7={false} />
        <StyledCardShadow sx={{ mt: 2 }}>
          <CardContent sx={{ py: 1 }}>
            <DataTable
              payload={payload}
              exportWithAll={false}
              isLoading={isLoading}
              includeSearchBox={true}
              handleChange={handleSearch}
              columns={columns}
              data={responseData}
              onChangeXcl={onChangeXcl}
              styles={{ maxWidth: "89vw" }}
              numberOfStickyColumns={false}
              page={newPage}
              rowsPerPage={rowsPerPage}
              callbackChangePage={pageChange}
              totalElements={totalElements}
              totalPages={totalPages}
            />
          </CardContent>
        </StyledCardShadow>
      </Box>
    </StyledPageLayout>
  );
};
export default ManualCanDataReport3WMontra;
import { Box, FormLabel,  Icon, InputBase, Paper, Stack,  useTheme } from '@mui/material'
import React, { useState } from 'react'
import { SoftButton, StyledFormControl, StyledPaperShadow, StyledTextField } from '../../theme/StyledEle'
import { useLocation, useNavigate } from 'react-router-dom';
import FormDialog from '../../Utils/FormDialog';
import { useForm } from 'react-hook-form';
import { MdSwapHoriz } from 'react-icons/md';
import { useSwapImeiForDeviceMutation } from '../../store/slice/AdminSlice/AdminSlice';
import StyledSnackBar from '../../Utils/StyledSnackBar';


function ImeiSwap(onDeviceChange) {

    const re = /^[0-9\b]+$/;
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const {
        register,
        setValue,
        getValues,
        reset,
        formState: { errors },
      } = useForm({
        mode: "all",
      });

    const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
    const [dialogContent, setDialogContent] = useState({});
    const [open, setOpen] = useState(false);

    const [changeIMEI, { data: changeIMEIData, isLoading, isSuccess }] =
    useSwapImeiForDeviceMutation();

    const handleClick = (event) => {
      navigate('#changeIMEI');
      reset({
       vin:onDeviceChange?.onDeviceChange[0]?.values.chassisNo,
      oldIMEI:onDeviceChange?.onDeviceChange[0]?.values.imei
      })
    };

    const resetForm =() =>{
        setValue('newIMEI','');
    }

    const onsubmit= async() =>{
var request = {};
request.vin = onDeviceChange?.onDeviceChange[0]?.values.chassisNo;
request.oldIMEI =  onDeviceChange?.onDeviceChange[0]?.values.imei;
request.newIMEI =  getValues('newIMEI');
const response = await changeIMEI(request);
var obj1 = {};

obj1.dialogText = response.data.resultMessage;
setDialogContent(obj1);
setDataStatusConfirm(response.data.result);
setOpen(true);
console.log(`${location.pathname}`);

setTimeout(() => {
  setOpen(false);
}, 2000);
if (response.data.result === "Success"){
  resetForm()
  navigate(`${location.pathname}`);

}

    }

  return (
    <>
    <SoftButton
    className="secondary"
    startIcon={ <MdSwapHoriz />}
    aria-haspopup="true"
    onClick={handleClick}
    disableElevation
    color="secondary"
    size="small"
  >
    Change Device
  </SoftButton>

    <FormDialog title={'IMEI SWAP'} padding={false} size="sm">
    <Box>
        <StyledPaperShadow sx={{mt:2, overflow:'hidden'}}>
            <Stack spacing={2}> 
            <StyledFormControl sx={{display:''}} fullWidth>
                                <FormLabel>Vehicle Vin No</FormLabel>
                                <StyledTextField
                    error={errors.vin && true}
                    size="small"
                    fullWidth
                    {...register("vin", {
                      required: {
                        value: false,
                        message: "Required Field ",
                      },
                    })}
                    onChange={(e) => setValue("vin", e.target.value)}
                    helperText={errors.vin && errors.vin.message}
                    inputProps={{ maxLength: "25" }}
                 disabled={true}
                  />
                                </StyledFormControl>

                                <Paper elevation={0} sx={{border:`.5px solid ${theme.palette.divider}`, py:1}}>
                            <Stack direction={'row'} spacing={1.5}>
                                <InputBase sx={{px:1}}
                        error={errors.oldIMEI && true}
                        size="small"
                        fullWidth
                        placeholder='Old IMEI Number'
                        {...register("oldIMEI", {
                            required: {
                            value: false,
                            message: "Device IMEI Required",
                            },
                            maxLength: {
                            value: 15,
                            message: "Device IMEI no must be 15 Character ",
                            },
                            minLength: {
                            value: 15,
                            message: "Device IMEI no must be 15 Character",
                            },
                        })}
                
                   
                        onChange={(event) =>
                            event.target.value === "" || re.test(event.target.value)
                            ? setValue("oldIMEI", event.target.value)
                            : setValue("oldIMEI", "")
                        }
                        helperText={errors.oldIMEI && errors.oldIMEI.message}
                                inputProps={{ maxLength:'15' }}
                                disabled={true}
                            />
                            <Icon color='secondary'>
                                <MdSwapHoriz />
                            </Icon>

                            <InputBase sx={{px:1}}
                      error={errors.newIMEI && true}
                      size="small"
                      fullWidth
                      placeholder='New IMEI Number'
                      {...register("newIMEI", {
                        required: {
                          value: true,
                          message: "Device IMEI Required",
                        },
                        maxLength: {
                          value: 15,
                          message: "Device IMEI no must be 15 Character ",
                        },
                        minLength: {
                          value: 15,
                          message: "Device IMEI no must be 15 Character",
                        },
                      })}
                     // onChange={(e) => setValue("imei", e.target.value)}
                      onChange={(event) =>
                        event.target.value === "" || re.test(event.target.value)
                          ? setValue("newIMEI", event.target.value)
                          : setValue("newIMEI", "")
                      }
                      helperText={errors.newIMEI && errors.newIMEI.message}
                            inputProps={{ maxLength:'15' }}
                    />

                            </Stack>
                        </Paper>
                        <Stack direction={'row'} spacing={2} sx={{mt:2}} justifyContent={'end'}>
            <SoftButton variant='contained' color='success' className='success' onClick={onsubmit} size='small'>
            {
                'Save'
              }
           
                
            </SoftButton>
            <SoftButton variant='contained' color='error' onClick={resetForm} className='error' size='small'>Reset</SoftButton>
        </Stack>
            </Stack>

        </StyledPaperShadow>
    </Box>
    </FormDialog>
    {open && (
        <StyledSnackBar
          open={true}
          message={dialogContent.dialogText}
          status={dataStatusConfirm}
        />
      )}
    </>
  )
}

export default ImeiSwap
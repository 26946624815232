import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { DrawingManager, GoogleMap, Polygon, useJsApiLoader } from "@react-google-maps/api"; 
import { Breadcrumb } from "../shared/Breadcrumbs";
import FilterDropdown from '../NewDropdown/FilterDropdown';

import {
  Box,
  Typography,
  ListItemText,
  List,
  Checkbox,
  Grid,
  ListItemButton,
  ListItemIcon,
  Collapse,
  Backdrop,
  Stack,
} from "@mui/material";
import { GOOGLE_MAP_KEY } from "../../constants/appConstant";
import { useTheme } from "@mui/material";
import CreateZone from "./CreateZone";
import GeoZoneSearchBox from "./SearchBox";
import Divider from "@mui/material/Divider";
import { MdLocationPin } from "react-icons/md";
import {
  SoftButton,
  StyledListItem,
  StyledPageLayout,
  StyledPaperShadow,
  StyledToggleButton,
  StyledToggleButtonGroup,
} from "../../theme/StyledEle";
import { Dark, Day } from "../../theme/gmapTheme";
import { useDispatch, useSelector } from "react-redux";
//map Layer icon
import { useAssignGeoZoneMutation, useCheckExistZoneEditMutation, useCheckExistZoneMutation, useDeleteGeoZoneMutation, useGeoAssignedVehicleMutation, useGeozoneGridViewMutation, useGetAllGeoZoneForOrgMutation, useGetAllGeoZoneForVehicleMutation, useLoadAllGeoZoneMutation, useSaveGeoZoneMutation ,useGeoZoneCategoryListMutation} from "../../store/slice/GeozoneSlice/GeozoneSlice";
import ScaleLoader from "react-spinners/ScaleLoader";
import GridLoader from "react-spinners/GridLoader";
import StyledSnackBar from "../../Utils/StyledSnackBar";
import { snackService } from "../../store/slice/ControlsSlice";
import ZoneMarker from "./ZoneMarker";
import ZonePolygon from "./ZonePolygon";
import SelectTypeMarker from "./SelectTypeMarker";
import ZoneInfo from "./InfoWindow";
import MapOptions from "../../Utils/MapOptions";
import {useAuditLogMutation} from "../../store/slice/ApiSlice";
import LeafletGeozone from "./LeafletGeozone"
import {
  ExportCsvoptions,ExportPdfoptions
} from "../../theme/StyledEle";

const data = [
  {
    id: 1,
    name: "Chennai",
    latitude: "13.0826802",
    longitude: "80.2707184",
    radius: 500,
    isChecked: 0,
  },
  {
    id: 2,
    name: "Kelambakkam",
    latitude: "12.783791",
    longitude: "80.217800",
    radius: 500,
    isChecked: 1,
  },
  {
    id: 3,
    name: "Vandalor",
    latitude: "13.0164878",
    longitude: "79.950334",
    radius: 500,
    isChecked: 0,
  },
];
const assignButtonData = [
  {
    id: 1,
    name: "Assign",
  },
  {
    id: 2,
    name: "Cancel",
  },
];



const containerStyle = {
  height: "65vh",
  width: "100%",
  borderRadius:'15px',
};

const GeoZone = () => {

  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;
  let orgType = userData.userOrgType;
  let roleId = userData.roleId;
  let mapTypeData = userData.mapType;

  useEffect(()=>{
    setGlobalMap(mapTypeData)
  },[mapTypeData])

  const togButton = [
    {
      id: 1,
      name: "Manage GeoZones",
    },
    {
      id: 2,
      name: "Assign GeoZones",
    },
  ];
  
  const manageButtonData = [
    {
      id: 1,
      name: "Create",
      class:'primary',
      show:true
    },
    {
      id: 2,
      name: "Edit",
      class:'info',
      show:true
    },
    {
      id: 3,
      name: "Delete",
      class:'error',
      show: resellerId === 110224 && (roleId === 17 || roleId === 6 || roleId === 8  ) ? true : resellerId !== 110224 ? true : false
    },
  ];

  const theme = useTheme();
  const [alignment, setAlignment] = useState("Manage GeoZones");
  const [zoneNameSelted, setZoneNameSelted] = useState("");
  const [zoneNmSelted, setZoneNmSelted] = useState("");
  const [zoneTypeSelted, setZoneTypeSelted] = useState("");
  const [radiusSelted, setRadiusSelted] = useState(100);
  const [addressSelted, setAddressSelted] = useState("");

  const [isCreate, setIsCreate] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [dataObjZone, setDataObjZone] = useState(1);
  const [dataObjRadius, setDataObjRadius] = useState(100);
  const [_data, set_data] = useState([]);
  const [drawPolyline, setDrawPolyline] = useState(true);
  const [assignVhlGlb, setAssignVhlGlb] = useState([]);
  const [unAssignVhlGlb, setUnAssignVhlGlb] = useState([]);
  const [payload, setPayload] = useState([]);
  const [selectedZoneId, setSeletedZoneId] = useState(null);


  const [ getGeoZoneForOrg, {data:geoZoneOrg, isLoading:geoZoneOrgLoading, isSuccess:geoZoneOrgSuccess}] = useGetAllGeoZoneForOrgMutation();
  const [ getAllGeoZoneData, {data:geoZoneData, isLoading:geoZoneDataLoading, isSuccess:geoZoneDataSuccess}] = useLoadAllGeoZoneMutation();
  const [ getGeoZoneGridview, {data:geoZoneGridView, isLoading:gridViewLoading, isSuccess:gridViewSuccess}] = useGeozoneGridViewMutation();
  const [ getAllGeoZoneForVehicle, {data:geoZoneForVehicle, isLoading:geoZoneForVehicleLoading, isSuccess:geoZoneForVehicleSuccess}] = useGetAllGeoZoneForVehicleMutation();

  const [ saveGeoZone, {data:saveGeoZoneData, isLoading:saveGeoZoneLoading, isSuccess:saveGeoZoneSuccess}] = useSaveGeoZoneMutation();
  const [ checkGeoZone, {data:checkGeoZoneData, isLoading:checkGeoZoneLoading, isSuccess:checkGeoZoneSuccess}] = useCheckExistZoneMutation();
  const [ checkGeoZoneEdit, {data:checkGeoZoneEditData, isLoading:checkGeoZoneEditLoading, isSuccess:checkGeoZoneEditSuccess}] = useCheckExistZoneEditMutation();
  const [ deleteGeoZone, {data:deleteGeoZoneData, isLoading:deleteGeoZoneLoading, isSuccess:deleteGeoZoneSuccess}] = useDeleteGeoZoneMutation();
  const [ showAssignedZone, {data:showAssignedZoneData, isLoading:showAssignedLoading, isSuccess:showAssignedSuccess}] = useGeoAssignedVehicleMutation();
  const [ assignZoneVehicle, {data:assignZoneData, isLoading:assignZoneLoading, isSuccess:assignZoneSuccess}] = useAssignGeoZoneMutation();
  
  //sector
  const isSuccess = useSelector((state) => state.controls.alert);
  const dispatch = useDispatch();
  const tabsOpt = useSelector((state) => state.controls.customizer.currentPage.buttonOpt);
  
    //ZonList
    const [zoneList, setZoneList] = useState(null);
    const [isRider, setIsRider] = useState(false)

  //Map Options
  const gmode = useSelector((state) => state.controls.customizer.mapMode);
  const [libraries] = useState(["drawing", "places", "geometry"]);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_MAP_KEY,
    libraries
  });
  let mapRef = useRef();
  const [map, setMap] = useState(null);
  const onLoad = React.useCallback(
    function callback(map) {
      setMap(map);
      mapRef.current = map;
    }, 
      [gmode]
    );
  const onUnmount = React.useCallback(function callback(map) {setMap(null)}, []);

  let [defaultZoom, setDefaultZoom] = useState(5);
  let [defaultCenter, setDefaultCenter] = useState({lat: 21.28, lng: 78.83});
  let [createMarkerData, setCreateMarkerData] = useState({lat: null, lng: null, address:null, radius:null, allData:null});
  let [editData, setEditData] = useState({lat: null, lng: null, address:null, radius:null});
  let [drawingMode, setDrawingMode] = useState(null);
  const [shapes, setShapes] = useState('');
 const [globalMap,setGlobalMap]= useState(1);

  //Handlers
  //Geo Zone State
  const [zoneName, setZoneName ] = useState('');
  const [zoneCategory, setzoneCategory] = useState();
  const [selectedData, setSelectedData] = useState('General');
  const [zoneModel, setZoneModel] = useState('Circles');
  const [zoneType, setZoneType] = useState(1);
  const [zoneRadius, setZoneRadius] = useState(100);
  const [address, setAddress] = useState(null);
  const [polygonPath, setPolygonPath] = useState([])
  const [markerData, setMarkerData] = useState(null);


  //Select Zone
  const [ selectZone, setSelectZone ] = useState([]);

  const [hoverZone, setHoverZone] = useState(null);

  const [openpopup, setOpenpPopup] = useState(false)

    //geozone list
    const [geoZoneCategoryList,{ data: geoZoneCategoryListData,}] = useGeoZoneCategoryListMutation();
    let commonListPayload = {
      sessionOrgId:resellerId
    }
    useEffect(()=>{
      geoZoneCategoryList(commonListPayload)
    },[])
    useEffect(() => {
      if (geoZoneCategoryListData) {
        const values = geoZoneCategoryListData.data.map(item => item.value);
        setzoneCategory(values);
      }
    }, [geoZoneCategoryListData]);

  //Audi Log
  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, 
    orgName: orgName, 
    userName: userName,
  };
  useEffect(async () => {
    auditPayload.message = "Tracking > GeoZone";
    auditPayload.action = "View";
    auditPayload.featureName = "GeoZone";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditLog(auditPayload);
    
  },[])


  let drawingManagerRef = useRef();

  //OnSubmit
  //Dropdown Submit
  let commonPayload = {}
  let [compay, setCompay] = useState(null)
  const onSubmitForm = (data) => {
    setDrawingMode(null);
    setMarkerData(null);
    setPayload(null);
    setFleet(null);
    set_data([]);
    setSelectZone(null);
    setIsCreate(false);
    setIsEdit(false);
    commonPayload.orgId = data.fleetId;
    commonPayload.category = 'parent';
    commonPayload.vehicleId = data.vehicleId;
    commonPayload.fleetId = data.fleetId;
    
    setFleet(data.fleetId);
    setPayload(commonPayload);
    if(data.fleetId !== undefined && data.fleetId !== null){
      getGeoZoneList(data, alignment);

      auditPayload.message = "Tracking > Geo Zones > "+data.regionName+" > "+data.dealerName+" > "+data.customerName+" > "+data.fleetName+" > "+data.vehicleName+" > "+"Submit";
      auditPayload.action = "Submit";
      auditPayload.featureName = "Geo Zones";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditPayload.userName = userName;      
      auditLog(auditPayload);
    }
}



// Call Condition
const getGeoZoneList = async (formData, newAlignment) => { // Based on fleet call
  setCompay(formData);
  setUnAssignVhlGlb([]);
  if(formData.vehicleId === '-1' && formData.vehicleId !== undefined && formData.fleetId !== undefined){
    const getAllGeozoneList = await getGeoZoneForOrg(commonPayload);
    setZoneList(getAllGeozoneList?.data?.data);
    set_data(getAllGeozoneList?.data?.data);
    setIsRider(false)
  }else{
    if(newAlignment === 'Assign GeoZones'){
      commonPayload.category = '';
      const singleVehicleList = await showAssignedZone(commonPayload);
      setZoneList(singleVehicleList?.data?.data);
      set_data(singleVehicleList.data.data);
      const getAssigned = singleVehicleList.data.data.filter((item) => item.isChecked === 1);
      setSeletedZoneId(getAssigned);
      
    }else{
      commonPayload.category = '';
      const singleVehicleList = await getAllGeoZoneForVehicle(commonPayload);
      setZoneList(singleVehicleList?.data?.data);
      set_data(singleVehicleList?.data?.data);
      if(singleVehicleList?.data?.rider !== undefined){
        setIsRider(singleVehicleList?.data?.rider)
      }else{
        setIsRider(false);
      }
      
    }    
  }
}

useEffect(() => {
  if(zoneList !== null && zoneList.length > 0){
    resetMap();  
  }
},[zoneList])

//Reset Map with all
const resetMap = () => {
  //setDefaultZoom(map?.zoom);
  //setDefaultCenter({lat:parseFloat(zoneList[0].latitude), lng:parseFloat(zoneList[0].longitude)});
  map?.panTo({lat:parseFloat(zoneList[0].latitude), lng:parseFloat(zoneList[0].longitude)});
}



//Refs
const checkRef = useRef();



  //Handler

  //Zone Type
  const handleZoneType = (zoneType) => {
    setZoneModel(zoneType.props.value);
    //setDefaultZoom(map?.zoom);
    if(zoneType.props.value !== 'Circles'){
      setZoneRadius(100);
      setDrawingMode('polygon');
      setShapes(null);
    }else{
      setDrawingMode(null);
      
    }
  }

  //Zone Name
  const handleZoneName = (zoneName) => {
    setZoneName(zoneName);
  }
  //zone category
  const handleZoneCategory= (zoneCategory) => {
     setSelectedData(zoneCategory);
  }
   //Zone Radius
   const handleZoneRadius = (zoneRadius) => {
    setZoneRadius(zoneRadius.props.value);
    //setDefaultZoom(14);
  }



;


  //Get Address from geoZone
  const callbackgeometry = (lat, lng, address) => {
    //setLoc({lat:lat, lng:lng});
    setDefaultCenter({lat:lat, lng:lng})
    setCreateMarkerData({lat:lat, lng:lng, address:address, radius:zoneRadius, allData:null});
    setAddress(address);
    if(isCreate){
      //setDefaultZoom(14);
      map?.panTo({lat:lat, lng:lng});
      setDefaultCenter({lat:lat, lng:lng});
      map.setZoom(16);
    }else if(isEdit){
      setDefaultCenter({lat:lat, lng:lng});
      map?.panTo({lat:lat, lng:lng});
      setCreateMarkerData({lat:lat, lng:lng, address:address, radius:zoneRadius, allData:null});
    }
  };



  //Polygon
  const handleDraw = useCallback(
    (shape) => {
      setShapes(shape);
    },
    [shapes]
  );
  

  const [polyShape, setPolyShape] = useState(null);

  //OnComplete Polygon
  const handleOnPolygonComplete = useCallback(
    (polygon) => {
      setPolyShape(polygon.overlay);
      if (polygon.overlay.getPath() !== null) {
        const polygonPathArray = polygon.overlay.getPath().getArray();
        if (polygonPathArray) {
          const path = polygonPathArray.map(latLngLiteral => {
            return { lat: latLngLiteral.lat(), lng: latLngLiteral.lng() };
          });
            handleDraw({path, type: 'polygon'});
            setDefaultZoom(map?.zoom);
            
        }
      }
    },
    [handleDraw]
  );


  useEffect(() => {
    if(shapes !== null){
      resetPolygon();
    }
  },[shapes])

  const resetPolygon = () => {
    setDrawingMode(null);
  }
  
  const renderShapes = useCallback(
    () =>
      shapes.map((shape, idx) =>
        shape.type === 'polygon' ? (
          <Polygon key={idx} path={shape.path.map(latLng => latLng)} editable={isEdit}/>
        ) : null
      ),
    [shapes]
  );
  




  //Feature Fuction

  //List HandleMarker
  const hovermarker = (data) => {
    if(selectZone === null){
    setDefaultZoom(map?.zoom);
    setDefaultCenter({lat:parseFloat(data.latitude), lng:parseFloat(data.longitude)});
    //map?.panTo({lat:parseFloat(data.latitude), lng:parseFloat(data.longitude)});
    setHoverZone(data.id);
    }
    
  }



  //zone Select
  const handleSelectZone = (zoneData) => {
    if(zoneData.id !== (selectZone?.id || selectZone === null)){
      setMarkerData(zoneData);
      setDefaultZoom(map?.zoom);
      setAddress(zoneData.address);
      if(zoneData.zoneType === 'Circles'){
        setDefaultCenter({lat:parseFloat(zoneData.latitude), lng:parseFloat(zoneData.longitude)});
        map?.panTo({lat:parseFloat(zoneData.latitude), lng:parseFloat(zoneData.latitude)});
        setCreateMarkerData({lat:zoneData.latitude, lng:zoneData.longitude, address:zoneData.address, radius:zoneData.radius, allData:selectZone});
      }else{
        let coords = JSON.parse(zoneData.cordinates);
        setDefaultCenter({lat:parseFloat(coords[0].lat), lng:parseFloat(coords[0].lng)});
        map?.panTo({lat:parseFloat(coords[0].lat), lng:parseFloat(coords[0].lng)});
      }
      setSelectZone(zoneData);
    }
  }

  let markerRef = useRef();

  //Click Marker for Single
  const singleMarker = (data) => {
      setMarkerData(selectZone);
      setDefaultZoom(map?.zoom);
      setDefaultCenter({lat:selectZone.latitude, lng:selectZone.longitude});
  }

  const multipleMarker = (data) => {
    setMarkerData(data);
    setDefaultZoom(map?.zoom);
    setDefaultCenter({lat:data.latitude, lng:data.longitude});
  }

  const markerClose = () => {
      setMarkerData(null);
      setDefaultZoom(map?.zoom);
  }




 //Cancel
  const resetZone = () => {
    setCreateMarkerData(createMarkerData);
    setDefaultZoom(map?.zoom);
    setDrawingMode(null);
    setZoneModel('Circles');
    setShapes(null);
    onSubmitForm(payload);
    resetPolygon();
    polyShape?.setMap(null);
  }

  const [fleet, setFleet] = useState(null);

  //Payloads
  //isCreate
  let zoneCategoryValue = resellerId === 110224 ? selectedData : "General";
  let zonePayload = {
    sessionOrgId: orgId,
    flag: "save",
    category: "parent",
    userId: userId,
    zoneType: zoneModel,
    zoneCategory: zoneCategoryValue,
    zoneName: zoneName,
    orgId: fleet,
  
    
  };

  //Save Zone
  const handleSaveZone = async(type) => {
    //Check type
    if(zoneModel === 'Circles')
      {saveCirecle()}
    else{savePolygon()}
    if(zoneName !== null && zoneName !== ''){
        if(address !== null && address !== undefined ){
              if(type === 'edit'){
                zonePayload.zoneId = selectZone.id;
                zonePayload.zoneNameOld = selectZone.name;
                zonePayload.geoCodeAddress = address;
              }
              zonePayload.flag = type;
              //Check Exisit GEO Zone Name
              let checkCode = 0;
              if(selectZone?.name !== zoneName) {
                  const checkZoneName = await checkGeoZone(zonePayload);
                  checkCode = checkZoneName.data.resultCode;
                  console.log(checkCode);
                  if(checkZoneName.data.resultCode === 1){
                    dispatch(snackService([checkZoneName.data.resultMessage, 1, true]))
                  }
              }
              if(checkCode === 0){
                  const responseData = await saveGeoZone(zonePayload);
                  dispatch(snackService([responseData.data.resultMessage, 0, true]));
                  if(responseData.data.resultCode === 0){
                    onSubmitForm(payload);
                    setShapes(null);
                    polyShape?.setMap(null);
                    //drawingManagerRef.current.setMap(null);
                    //resetPolygon();
                    //setDrawingMode(null);
                  }
                }
              } 
      else{
          dispatch(snackService(['Please Select Location', 1, true]))
        }
    }else{
      dispatch(snackService(['Please enter the zone Name', 1, true]))
    }
  }



  //Buttons
  const editZone = () => {
      if(selectZone !== null && selectZone !== undefined){
          setIsCreate(false);
          setIsEdit(true);
          setZoneName(selectZone.name);
          setSelectedData(selectZone.zoneCategory);
          setZoneModel(selectZone.zoneType);
          setZoneRadius(selectZone.radius);
          setAddress(selectZone.address);
          let lat = parseFloat(selectZone.latitude);
          let lng = parseFloat(selectZone.longitude);
          if(selectZone.zoneType === 'Polygons'){
            let coords =  JSON.parse(selectZone.cordinates);
            setMap(null);
            setDrawingMode('polygon');
          }
      }else{
        dispatch(snackService(['Please Select Geo Zone', 1, true]))
      }
  } 

  const createZone = () => {
    setIsCreate(true);
    setIsEdit(false);
    setZoneName('');
    setZoneModel('Circles');
    setzoneCategory(zoneCategory)
    setZoneRadius(100);
    // setAddress(null);
    // setSelectZone(null);
    // setZoneList(null);
  }

  const deleteZone = async() => {
      if(selectZone !== null && selectZone !== undefined){
          const deleteResponce = await deleteGeoZone({'geoId':selectZone.id});
            dispatch(snackService([deleteResponce.data.resultMessage, deleteResponce.data.resultCode, true]));
            if(deleteResponce.data.resultCode === 0){
              onSubmitForm(payload, alignment);
              setSelectZone(null);
             
            }
      }else{
        dispatch(snackService(['Please Select Geo Zone', 1, true]))
      }
  }


  const handleManageTab = async (name) => {
    if (name === "Create") {createZone()} 
    else if (name === "Edit") {editZone();
      // if (selectZone !== null && selectZone !== undefined) {
      //   setIsCreate(false);
      //   setIsEdit(true);
      //   setZoneName(zoneNmSelted);
      //   setDataObjRadius(radiusSelted);
      //   if (zoneTypeSelted === "Polygons") {
      //     setDrawPolyline(true);
      //     var dataply = [];
      //     setDataObjZone(2);
      //     dataply = [..._data];
      //     for (var i = 0; i < _data.length; i++) {
      //       if (_data[i].id === zoneNameSelted) {
      //         //set_data([]);
      //         dataply[i].cordinates = "";
      //       }
      //     }
      //     set_data([]);
      //     set_data(dataply);
      //     setDataObjZone(2);
      //   } else {
      //     setDataObjZone(1);
      //   }
      // }
      // else {
      //     dispatch(snackService(['Please Select Geo Zone', 1, true]))
      // }
      //set_data([]);
      //zoneNameSelted
    }else if(name === 'Delete'){deleteZone()}
  };



  useEffect(() => {
    if(fleet === undefined && fleet !== null){
      setIsCreate(false);
      setIsEdit(false);
      set_data([]);
      setPayload(null);
    }
  },[fleet])


const checkValue = (event, id) => {

  if(event.target.checked){
    setSeletedZoneId([...selectedZoneId, id]);
  }else{
    const res = selectedZoneId.filter((item) => item.id !== id.id);
    setSeletedZoneId(res);
  }
}

//Assign/UnAssign GEO Zone
let assingPayload = {
    "flag": "assign",
    "userId": userId,
}

const assignGeoZone = async () => {
    var array2 = null;
    var count1 = 0;
    for (let i = 0; i < selectedZoneId.length; i++) {
      if (count1 === 0) {
        array2 = selectedZoneId[i].id;
      } else {
        array2 = array2 + "," + selectedZoneId[i].id;
      }
      count1++;
    }
    assingPayload.orgId = payload.orgId;
    assingPayload.vehicleId = payload?.vehicleId;
    assingPayload.geozoneList = array2;

    if(payload?.vehicleId !== '-1'){
        const assingZone = await assignZoneVehicle(assingPayload);
        if(assingZone.data.resultCode === 0){
          dispatch(snackService([assingZone.data.resultMessage, 0, true]))
        }else{
          dispatch(snackService([assingZone.data.resultMessage, 1, true]))
        }
    }
}

//Reset Assign GEO Zone
const resetAssinZone = () => {
  onSubmitForm(payload, alignment);
}

//Toggle Button Changed
  const handleChange = (event, newAlignment) => {
    if (newAlignment !== null) {
      commonPayload.orgId = compay.fleetId;
      commonPayload.category = 'parent';
      commonPayload.vehicleId = compay.vehicleId;
      commonPayload.fleetId = compay.fleetId;
      setAlignment(newAlignment);
      if(fleet !== undefined && fleet !== null){
        getGeoZoneList(compay, newAlignment);
      }else{
        setIsCreate(false);
        setIsEdit(false);
        set_data([])
      }
    }
    auditPayload.message = newAlignment ==="Manage GeoZones"?"Tracking > GeoZone >Manage":"Tracking > GeoZone >Assign";
    auditPayload.action = "View";
    auditPayload.featureName = "GeoZone";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditLog(auditPayload);
  };

  


  function isInArray(value, array) {
    return array.indexOf(value) > -1;
  }

  const handleListGeozoneClick = (zone) => {
    setSelectZone(zone);

    if (alignment === "Manage GeoZones") {
      setDrawPolyline(false);
      setZoneNameSelted(zone.id);
      setZoneNmSelted(zone.name);
      setZoneName(zone.name);
      setZoneTypeSelted(zone.zoneType);
      setRadiusSelted(zone.radius);
      setAddressSelted(zone.address);
      var defaultProps = {};
        if (zone.zoneType === "Circles") {
          setDataObjZone(1);
        } else if (zone.zoneType === "Polygons") {
          setZoneType("2");
          setDataObjZone(2);
          if (isEdit === true) {
            setDrawPolyline(true);
          }
        if (isEdit === true) {
          setZoneName(zone.name);
        }
        }

          if (alignment === "Assign GeoZones") {
            setDrawPolyline(false);
            var defaultProps = {};
            if (zone.zoneType === "Circles") {
              setDataObjZone(1);
            } else if (zone.zoneType === "Polygons") {
              setDataObjZone(2);
              if (isEdit === true) {
                setDrawPolyline(true);
              }
              setZoneNameSelted(zone.id);

            var assignVhl = [...assignVhlGlb];
            var unAssignVhl = [...unAssignVhlGlb];
            var dataply = [];
            dataply = [..._data];

            for (var i = 0; i < dataply.length; i++) {
              if (dataply[i].id === zone.id)
                if (
                  dataply[i].isChecked === undefined ||
                  dataply[i].isChecked === 0
                ) {

                // dataply[i].isChecked = 1;
                  if (!isInArray(dataply[i].id, assignVhl)) {
                    assignVhl.push(dataply[i].id);
                  }
                  if (isInArray(dataply[i].id, unAssignVhl)) {
                    var index = unAssignVhl.indexOf(dataply[i].id);
                    unAssignVhl.splice(index, 1);
                  }
                  setZoneNmSelted(zone.name);
                  setZoneTypeSelted(zone.zoneType);
                  //setRadiusSelted(zone.radius);
                  setAddressSelted(zone.address);
                  if (isEdit === true) {
                    setZoneName(zone.name);
                  }
                } else {
                  if (isInArray(dataply[i].id, assignVhl)) {
                    var index = assignVhl.indexOf(dataply[i].id);
                    assignVhl.splice(index, 1);
                  }
                  if (!isInArray(dataply[i].id, unAssignVhl)) {
                    unAssignVhl.push(dataply[i].id);
                  }
                // dataply[i].isChecked = 0;

                }
            }
            setUnAssignVhlGlb(unAssignVhl);
            setAssignVhlGlb(assignVhl);
            set_data([]);
            set_data(dataply);
          }
          }
  };
}




useEffect(() => {
  if(isCreate){
    setDefaultCenter(defaultCenter)
  }else{
    setCreateMarkerData({lat: null, lng: null, address:null, radius:null, allData:null});
  }
},[isCreate])



//Type Cirecle
const saveCirecle = () => {
    zonePayload.latLng = createMarkerData.lat + "," + createMarkerData.lng;
    zonePayload.radius = zoneRadius;
    zonePayload.vehicleId = (payload?.vehicleId === undefined || payload?.vehicleId === null)? "-1": payload?.vehicleId;
    zonePayload.geoCodeAddress = address;
}


//Type Poligon
const savePolygon = () => {
    let coords = JSON.stringify(shapes.path);
    zonePayload.latLng = coords;
    //zonePayload.radius = '100';
    zonePayload.vehicleId = (payload?.vehicleId === undefined || payload?.vehicleId === null)? "-1": payload?.vehicleId;
    zonePayload.geoCodeAddress = address;
}

  //Alert Popup auto close
  useEffect(() => {
    if (isSuccess.isSuccess) {
      setTimeout(() => dispatch(snackService([null, null, null])), 6000);
    }
  }, [isSuccess.isSuccess]);

  const onChangeXcl = async () => {
    try {
     var url =
     process.env.REACT_APP_API_BASE_URL +
     "GeoFence/getAllGeoZoneForOrgXls?orgId="+
     payload.orgId+
     "&category=" +
     payload.category+
     "&vehicleId=" +
     payload.vehicleId+
     "&fleetId=" +
     payload.fleetId;
     window.open(url);
     let auditPayload={};
     auditPayload.message = 'MapView > VehicleSummary > CSV';
     auditPayload.action = 'View';
     auditPayload.featureName = 'VehicleSummary';
     auditPayload.customerName = 'All';
     auditPayload.fleetName = 'All';
     auditPayload.result = 'Success';
     auditPayload.access = 'Portal';
     auditPayload.severity = 'INFO';
     
     auditLog(auditPayload);

      fetch(url, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(window.localStorage.getItem("jwToken")),
        },
      }) // FETCH BLOB FROM IT
        .then((response) => {
          response.blob();
        })
        .then((blob) => {})
        .catch((err) => {});
        
    } catch (error) {}
  };
  
const onChangePdf = async () => {
  handleOpenPopup();
  try {
    
  } catch (error) { }
};

  //Marker Drag
  const MarkerDrag = (event) => {
    setDefaultZoom(map?.zoom);
    setCreateMarkerData({lat:event.latLng.lat(), lng:event.latLng.lng(), address:address, radius:zoneRadius, allData:null})
  }


  const [polyPath, setPolyPath] = useState();
  let pathArry = [];

  
  //makePolygon
  const makePolygon = (event) => {
    setDefaultZoom(map?.zoom);
    setDefaultCenter({lat:event.latLng.lat(), lng:event.latLng.lng()})
    let path = {}
    path.lat = parseFloat(event.latLng.lat());
    path.lng = parseFloat(event.latLng.lat());
    pathArry.push(path);

    setPolyPath(path)
    //setPolyPath([...polyPath, pathArry]);
  }
  const [mapType, setMapType] = useState('roadmap');
  const handleZoom = (value, action) => {
    if(action === 'zoom'){
        setDefaultZoom(value);
    }else{
        setMapType(value);
        setDefaultZoom(map.zoom);
    }
    
}

const updateCreateMarkerData = (newLat, newLng) => {
  setCreateMarkerData(prevState => ({
    ...prevState,
    lat: newLat,
    lng: newLng,address:address, radius:zoneRadius, allData:null
  }));
};
const updateCreatedPolygonData=(newLatlng)=>{
  setShapes(newLatlng)
}

const handleOpenPopup = () => {
  setOpenpPopup(true)
}

  const onCenter = () => {
    if(!mapRef.current) return;
    const newZoom = mapRef.current.getZoom();
    setDefaultZoom(newZoom);
  }

 //Google Map
 const googleMap = useMemo(() => {
    return isLoaded ? (
      <GoogleMap
        mapContainerStyle={containerStyle}
        onLoad={onLoad}
        onCenterChanged={onCenter}
        //onUnmount={onUnmount}
        onClick={(event) => makePolygon(event)}
        options={{
          mapTypeId: mapType,
          ...gmode !== 1 ? Dark : Day,
          zoom:defaultZoom,
          center:defaultCenter,
        }}
      >
          {
            selectZone === null && <ZoneMarker data={createMarkerData} radius={zoneRadius}  isCreate={isCreate} selectMarker={null} listZone={zoneList} hoverZone={hoverZone} isEdit={isEdit} zoneType={zoneModel} multipleMarker={multipleMarker} MarkerDrag={MarkerDrag}/>
          }
          {
            selectZone === null && <ZonePolygon hoverZone={hoverZone} listZone={zoneList} multipleMarker={multipleMarker} />
          }
          {
            selectZone !== null && <SelectTypeMarker data={selectZone} isEdit={isEdit} zoneRadius={zoneRadius} createMarkerData={createMarkerData} singleMarker={singleMarker}/>
          }
          {/* Zone Polygon View */}
          {
            drawingMode !== null && zoneModel === 'Polygons' && shapes === null && 
              <DrawingManager
              options={{
                //drawingControl:  drawingMode !== null ? true : false,
                drawingControl:  drawingMode !== null ? true : false,
                drawingControlOptions:{
                  position: window.google.maps.ControlPosition.TOP_CENTER,
                  //drawingModes: drawingMode !== null ? [drawingMode] : null,
                  drawingModes: ['polygon'],
                },
                polygonOptions: {
                  editable: false
                },
              }}
              
              ref={drawingManagerRef}
              onOverlayComplete={handleOnPolygonComplete}
            />
          }
          {
            markerData !== null && !isEdit && <ZoneInfo data={markerData} markerClose={markerClose}/>
          }
      </GoogleMap>
  ) : ('Loading')

},[isLoaded, zoneNameSelted, mapType, isCreate, createMarkerData, hoverZone, zoneList, selectZone, zoneRadius, zoneType, drawingMode, shapes, isEdit, markerData, defaultZoom])

const leafletMap = useMemo(() => {
  return (
    <>
   
    {isLoaded ? (
      <LeafletGeozone  mapType={mapType} defaultZoom={defaultZoom}  zoneList={zoneList} address={address}zoneRadius={zoneRadius} createMarkerData={createMarkerData} selectZone={selectZone} isCreate={isCreate} zoneType={zoneModel}  updateCreateMarkerData={updateCreateMarkerData} drawingMode={drawingMode} updateCreatedPolygonData={updateCreatedPolygonData} isEdit={isEdit}/>
    ) : (
      'Loading'
    )}
  </>
  );
},[isLoaded, zoneNameSelted,defaultZoom, mapType, isCreate, createMarkerData, hoverZone, zoneList, selectZone, zoneRadius, zoneType, drawingMode, shapes, isEdit, markerData, defaultZoom])

let mapSetting;

if (globalMap === 1) {
  mapSetting = googleMap;
} else if (globalMap === 2) {
  mapSetting = leafletMap;
} else {
  mapSetting = googleMap;
}

const CheckBoxList = useCallback(({zoneItem, index}) => {
  return(
          <Checkbox 
            ref={checkRef}
            onChange={(event) => checkValue(event, zoneItem)}
            defaultChecked ={zoneItem.isChecked === 0 ? false : true}
            size="small"
            disabled={payload?.vehicleId !== '-1' ? false : true}
      /> 
  )
},[zoneList])
  return (
    <StyledPageLayout>
      <Breadcrumb mainDiv="Tracking" subDiv="Geo Zones" ></Breadcrumb>
      <FilterDropdown  getData={onSubmitForm} status={false} isShowAll={false} allowDays={2} onload={true} last7={false} showCalendar={false}/>
      <StyledPaperShadow sx={{ my: 2 }}>
        <StyledToggleButtonGroup
          color="primary"
          value={alignment}
          exclusive
          onChange={handleChange}
          size="small"
          sx={{alignItems:'center'}}
        >
          {togButton.map((bts, index) => (
            <StyledToggleButton key={index} value={bts.name}>
              {bts.name}
            </StyledToggleButton>
          ))}

          {alignment === "Manage GeoZones" && !isRider && !isCreate && !isEdit && fleet !== null && fleet !== undefined ?
          
          manageButtonData.map((manageBtList, i) => manageBtList.show && (
                  <Box key={i}>
                  <SoftButton
                    type="submit"
                    variant="contained"
                    onClick={() => handleManageTab(manageBtList.name)}
                    size="small"
                    className={manageBtList.class}
                    sx={{mx:1}}
                  >
                    {manageBtList.name}
                  </SoftButton>
                </Box>
              ))



              // Work based on role based
              // <Stack direction={'column'} spacing={1}>
              //   {
              //      GeoZoneAction?.map((item) => {
              //         if(tabsOpt?.includes(item.value)){
              //           return(
              //           <SoftButton
              //           type="submit"
              //           variant="contained"
              //           onClick={() => handleManageTab(item.label)}
              //           size="small"
              //           //className={item.class}
              //           color={item.color}
              //           sx={{mx:1}}
              //         >
              //           {item.label}
              //         </SoftButton>
              //           )
              //         } 
              //     })
              //   }
              // </Stack>



            : 
              payload?.vehicleId !== '-1' && fleet !== null && fleet !== undefined && alignment === 'Assign GeoZones' ?
              <Stack direction={'row'} sx={{ml:2}} spacing={2}>
                <SoftButton variant="contained" className="success" color="success" size="small" onClick={assignGeoZone}>
                    Assign
                </SoftButton>
                <SoftButton variant="contained" className="error" color="error" size="small" onClick={resetAssinZone}>
                    Reset
                </SoftButton>
              </Stack> : payload?.vehicleId === '-1' && alignment === 'Assign GeoZones' && <Typography variant="body2" sx={{ml:2}} color={'error'}>Please select vehicle to assign zone</Typography>
              }
        </StyledToggleButtonGroup>
      </StyledPaperShadow>

      {
        <Collapse in={isCreate || isEdit }>
            <StyledPaperShadow sx={{ mb: 2 }}>
            <CreateZone
              handleCancel={resetZone}
              //changefnZone={changefnZone}
              dataObjZone={dataObjZone}
              dataObjRadius={dataObjRadius}
              zoneName={zoneName}
              handleSave={handleSaveZone}
              isEdit={isEdit}
              handleZoneType={handleZoneType}
              handleZoneName={handleZoneName}
              handleZoneRadius={handleZoneRadius}
              handleZoneCategory={handleZoneCategory}
              zoneCategory={zoneCategory}
              zoneRadius={zoneRadius}
              zoneType={zoneType}
              zoneModel={zoneModel}
              selectedData={selectedData}
              resellerId={resellerId}
            />
        </StyledPaperShadow>
        </Collapse>
      }

<Box sx={{position:'relative', width:'100%', height:'100%'}}>
  {(isCreate || isEdit) && (
      <GeoZoneSearchBox
        callbackgeometry={callbackgeometry}
        addressSelted={addressSelted}
        isEdit={isEdit}
        zoneType={zoneType}
        address={address}
      />
  )}


  {/* Geo Zone List */}
<StyledPaperShadow
    sx={{
      background:theme.palette.background.paper,
      height:'auto',
      position:'absolute',
      top:'20px',
      left:'20px',
      width:'100%',
      maxWidth:'300px',
      flexDirection:'column',
      textAlign:'left', 
      alignItems:'baseline',
      maxHeight:'80%',
      height:'100%',
      overflowY:'scroll',
      zIndex:999,
    }}
  >
  <Typography variant="subtitle">
  <Grid container lg={12} sx={{ fontSize: '20px', ml: 'auto' }}>
    <Grid item xs={2} sx={{ textAlign: 'center' , marginLeft: '170px'}}>
      <Typography sx={{ paddingTop: '5px',paddingBottom: '10px', marginRight: '3px' }}>
        <ExportCsvoptions onChangeXcl={onChangeXcl} onChangePdf={onChangePdf} />
      </Typography>
    </Grid>
  </Grid>Zone List</Typography>
    {
      (geoZoneOrgLoading || showAssignedLoading || geoZoneForVehicleLoading) ? 
      <Box sx={{display:'flex', justifyContent:'center', alignItems:'center'}}><ScaleLoader color={theme.palette.secondary.main} height={12}/></Box> : 
        <List sx={{width:'100%'}} dense={true}>

        {zoneList !== null && zoneList?.length > 0 && !isEdit &&
          zoneList.map((zoneItem, index) => {
            return(
                <StyledListItem
                  key={index}
                  dense={true}
                  className={'zoneButtonRoot'}
                  id={zoneItem.id}
                >
                  <ListItemButton
                  //onClick={(e) => handleListGeozoneClick(zoneItem)}
                  onClick={(e) => handleSelectZone(zoneItem)}
                  selected={selectZone !== null && selectZone.id === zoneItem.id ? true : false}
                  id="listBtn"
                  disabled={selectZone !== "" && isEdit === true ? true:false}
                  onMouseEnter={() => hovermarker(zoneItem)}
                  onMouseLeave={() => setHoverZone(null)}
                  >
                    {
                        alignment === 'Assign GeoZones' ?
                        <CheckBoxList zoneItem={zoneItem} index={index}/>
                      :
                      <ListItemIcon>
                        <MdLocationPin />
                      </ListItemIcon>
                    }
                    <ListItemText
                      primary={<Typography>{zoneItem.name}</Typography>}
                    />
                  </ListItemButton>
                  <Divider />
                </StyledListItem>
            )
          })
        }

        {
          (isEdit || isCreate) &&
          <StyledListItem  className={'zoneButtonRoot'} >
          <ListItemButton selected disabled>
            <ListItemIcon>
                <MdLocationPin />
            </ListItemIcon>
            <ListItemText
                primary={<Typography>{zoneName}</Typography>}
            />
          </ListItemButton>
          </StyledListItem>
        }
        </List>
    }

  {/* </Scrollbars> */}
</StyledPaperShadow>
<Box sx={{position:'relative', height:"100%"}}>
  {mapSetting}
  <MapOptions map={map} zoomValue={defaultZoom} mapLayer={mapType} handleZoom={handleZoom}  mapTypeData={mapTypeData} />
</Box>


{/* <ControlsBox className='zoom-control-wrapper' direction='row'
  sx={{
          flexDirection:'column',
          alignItems:'center',
          justifyContent:'center',
        
  }}>
      <Button className="map-btn" onClick={() => setZoom(map.zoom + 1)} disabled={zoom === 22}><RiZoomInLine/></Button>
      <Button className="map-btn" onClick={() => setZoom(map.zoom - 1)} disabled={zoom === 3}><RiZoomOutLine/></Button>
      <Button className="map-btn"
      id="basicmaplayer-button"
      aria-controls={openMap ? 'maplayer-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={openMap ? 'true' : undefined}
      onClick={handleMapLayer}
      >
          <FiLayers />
      </Button>
      <Menu
    id="maplayer-menu"
    anchorEl={openMapEl}
    open={openMap}
    onClose={handleMapLayerClose}
    MenuListProps={{
      'aria-labelledby': 'maplayer-button',
    }}
    anchorOrigin={{
      vertical: '',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: '',
      horizontal: 'right',
    }}
  >
        <ListItemButton className="small-menuItem" selected={mapType === 'roadmap'} onClick={() => handleMapType('roadmap')}>
            <ListItemAvatar>
                <Avatar src={DefaultMap} variant="rounded" sx={{width:'25px', height:'25px'}}/>
            </ListItemAvatar>
            <ListItemText>RoadMap</ListItemText>
        </ListItemButton>
        <ListItemButton className="small-menuItem" selected={mapType === 'terrain'} onClick={() => handleMapType('terrain')}>
            <ListItemAvatar>
                <Avatar src={Terrain} variant="rounded" sx={{width:'25px', height:'25px'}}/>
            </ListItemAvatar>
            <ListItemText>Terrain</ListItemText>
        </ListItemButton>
        <ListItemButton className="small-menuItem" selected={mapType === 'satellite'} onClick={() => handleMapType('satellite')}>
            <ListItemAvatar>
                <Avatar src={Satellite} variant="rounded" sx={{width:'25px', height:'25px'}}/>
            </ListItemAvatar>
            <ListItemText>Satellite</ListItemText>
        </ListItemButton>
    </Menu>
</ControlsBox> */}
</Box>
          <Backdrop open={saveGeoZoneLoading || deleteGeoZoneLoading || checkGeoZoneLoading || assignZoneLoading } sx={{zIndex:999}}>
              <GridLoader color="#36d7b7" />
          </Backdrop>

      {
        isSuccess.isSuccess &&
        <StyledSnackBar message={isSuccess.message} status={isSuccess.status} />
      }
    </StyledPageLayout>
  );
  };

export default GeoZone;
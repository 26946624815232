import React, { useMemo, useState, useEffect } from "react";
import { Box } from "@mui/system";
import { DataTableNew } from "../shared/data_table/DataTableNew";
import ReportServices from "../../services/reportServices";
import { CardHeader, CardContent, Typography } from "@mui/material";
import { Breadcrumb } from "../shared/Breadcrumbs";
import { useTheme } from "@mui/styles";
import MouseOverPopover from "../shared/PopOver";
import ReportNewDropDown from "../shared/ReportsDropDownNew";
import logServices from "../../services/logService";
import StyledTooltip from "../shared/StyledTooltip";

import {
  StyledCardShadow,
  Exportoptions,
  StyledPageLayout,
  StyledTableTitle,
} from "../../theme/StyledEle";
import addDays from "date-fns/addDays";
import { format } from "date-fns";
import FilterDropdown from "../NewDropdown/FilterDropdown";
import {
  useAuditLogMutation,
  useGetSummaryFleetWiseListMutation,
} from "../../store/slice/ApiSlice";

const text =
  "This Report gets the trip & battery details for Vehicle-wise or Day-wise. Energy Efficiency is calculated for vehicles that have traveled more than 10km per day";

const RouteFleetWiseReport = ({
  data,
  footerData,
  payload,
  isLoadingOut,
  handleSearch,
  pageChange,
  newPage,
  rowsPerPage,
  totalElements
}) => {
  console.log(footerData);
  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;



  const theme = useTheme();
  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, //User ID
    orgName: orgName, //Org Name
    userName: userName,
  };

  useEffect(async () => {

    auditPayload.message = "Reports > RouteSummary-Fleetwise";
    auditPayload.action = "View";
    auditPayload.featureName = "Monthly Summary";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditLog(auditPayload);
  }, []);

  const columns = [
    {
      Header: "Vehicle Number",
      accessor: "vehicleNo",
      width: 180,
      show: true,
      Footer: "Total:"
    },
    {
      Header: "Trip Count",
      accessor: "tripCount",
      show: true,
      Footer: info => {
        return <> {footerData?.totalTripCount}</>;
      }
    },
    {
      Header: "Total Duration",
      accessor: "durationTime",
      show: true,
      Footer: info => {
        return <> {footerData?.totalDurationTime}</>;
      }
    },
    {
      Header: "(A+B+C+D)",
      columns: [
        {
          Header: "Plant Area Time",
          accessor: "plantAreaTime",
          show: true,
          Footer: info => {
            return <> {footerData?.totalPlantAreaTime}</>;
          }
        }
      ]
    }, {
      Header: "A",
      columns: [
        {
          Header: "Plant Area Stop Time",
          accessor: "plantStopMins",
          show: true,
          Footer: info => {
            return <> {footerData?.totalPlantStopMins}</>;
          }
        }
      ]
    },
    {
      Header: "B",
      columns: [
        {
          Header: "Plant Area Move Time",
          accessor: "plantMoveTime",
          show: true,
          Footer: info => {
            return <> {footerData?.totalPlantMoveTime}</>;
          }
        }
      ]
    },
    {
      Header: "C",
      columns: [
        {
          Header: "Loading Stop Time",
          accessor: "loadStopMins",
          show: true,
          Footer: info => {
            return <> {footerData?.totalLoadStopMins}</>;
          }
        }
      ]
    },
    {
      Header: "D",
      columns: [
        {
          Header: "Unloading Stop Time",
          accessor: "unLoadStopMins",
          show: true,
          Footer: info => {
            return <> {footerData?.totalUnLoadStopMins}</>;
          }
        }
      ]
    },
    {
      Header: "(E+F+G)",
      columns: [
        {
          Header: "Total charging area time",
          accessor: "chargeAreaTime",
          show: true,
          Footer: info => {
            return <> {footerData?.totalChargeAreaTime}</>;
          }
        }
      ]
    },
    {
      Header: "E",
      columns: [
        {
          Header: "Plugged Time",
          accessor: "chargingSummaryMins",
          show: true,
          Footer: info => {
            return <> {footerData?.totalChargeSummaryMins}</>;
          }
        }
      ]
    },
    {
      Header: "F",
      columns: [
        {
          Header: "Charging Area Stop Time",
          accessor: "chargeStopMins",
          show: true,
          Footer: info => {
            return <> {footerData?.totalChargeStopMins}</>;
          }
        }
      ]
    },
    {
      Header: "G",
      columns: [
        {
          Header: "Charging Area Move Time",
          accessor: "chargingMoveTime",
          show: true,
          Footer: info => {
            return <> {footerData?.totalChargingMoveTime}</>;
          }
        }
      ]
    },
    {
      Header: "Move Time",
      accessor: "moveMins",
      show: true,
      Footer: info => {
        return <> {footerData?.totalMoveMins}</>;
      }
    },
    {
      Header: "(H+I)",
      columns: [
        {
          Header: "Stoppage Time",
          accessor: "stopAreaTime",
          show: true,
          Footer: info => {
            return <> {footerData?.totalStopAreaTime}</>;
          }
        }
      ]
    },
    {
      Header: "H",
      columns: [
        {
          Header: "Scheduled Stop Time",
          accessor: "scheduledStopMins",
          show: true,
          Footer: info => {
            return <> {footerData?.totalScheduledStopMins}</>;
          }
        }
      ]
    },

    {
      Header: "I",
      columns: [
        {
          Header: "Unscheduled Stop Time",
          accessor: "unScheduledStopMins",
          show: true,
          Footer: info => {
            return <> {footerData?.totalUnScheduledStopMins}</>;
          }
        }
      ]
    },
    {
      Header: "(J+K+L)",
      columns: [
        {
          Header: "Total Maintanence Duration",
          accessor: "maintanenceTime",
          show: true,
          Footer: info => {
            return <> {footerData?.totalMaintanenceTime}</>;
          }
        }
      ]
    },
    {
      Header: "J",
      columns: [
        {
          Header: "Job by Customer",
          accessor: "customerJobTime",
          show: true,
          Footer: info => {
            return <> {footerData?.totalCustomerJobTime}</>;
          }
        }
      ]
    },
    {
      Header: "K",
      columns: [
        {
          Header: "Scheduled Service AMC",
          accessor: "serviceTime",
          show: true,
          Footer: info => {
            return <> {footerData?.totalServiceTime}</>;
          }
        }
      ]
    },
    {
      Header: "L",
      columns: [
        {
          Header: "Breakdown (IPLT)",
          accessor: "bdDurationTime",
          show: true,
          Footer: info => {
            return <> {footerData?.totalBdDurationTime}</>;
          }
        }
      ]
    },
    {
      Header: "Gained SOC(%)",
      accessor: "totalSocGained",
      show:true,
      Footer: info => {
        return <> {footerData?.totalSocGained}</>;
      }
    },
    {
      Header: "Energy Efficiency",
      accessor: "energyEfficiency",
      show: true,
      Footer: info => {
        return <> {footerData?.totalEnergyEfficiency}</>;
      }
    },
    {
      Header: "Avg Total Duration",
      accessor: "durationAvgTime",
      show: true,
      Footer: info => {
        return <> {footerData?.totalAvgDurationTime}</>;
      }
    },
    {
      Header: "(A+B+C+D)",
      columns: [
        {
          Header: "Avg Plant Area Time",
          accessor: "plantAreaAvgTime",
          show: true,
          Footer: info => {
            return <> {footerData?.totalAvgPlantAreaTime}</>;
          }
        }
      ]
    },
    {
      Header: "A",
      columns: [
        {
          Header: "Avg Plant Area Stop Time",
          accessor: "avgPlantStopMins",
          show: true,
          Footer: info => {
            return <> {footerData?.totalAvgPlantStopMins}</>;
          }
        }
      ]
    },
    {
      Header: "B",
      columns: [
        {
          Header: "Avg Plant Area Move Time",
          accessor: "plantMoveAvgTime",
          show: true,
          Footer: info => {
            return <> {footerData?.totalAvgPlantMoveTime}</>;
          }
        }
      ]
    },
    {
      Header: "C",
      columns: [
        {
          Header: "Average Loading Stop Time",
          accessor: "avgLoadStopMins",
          show: true,
          Footer: info => {
            return <> {footerData?.totalAvgLoadStopMins}</>;
          }
        }
      ]
    },
    {
      Header: "D",
      columns: [
        {
          Header: "Average Unloading Stop Time",
          accessor: "avgUnLoadStopMins",
          show: true,
          Footer: info => {
            return <> {footerData?.totalAvgUnLoadStopMins}</>;
          }
        }
      ]
    },
    {
      Header: "(E+F+G)",
      columns: [
        {
          Header: "Avg Total charging area time",
          accessor: "chargeAreaAvgTime",
          show: true,
          Footer: info => {
            return <> {footerData?.totalAvgChargeAreaTime}</>;
          }
        }
      ]
    },
    {
      Header: "E",
      columns: [
        {
          Header: "Average Plugged Time",
          accessor: "avgChargingSummaryMins",
          show: true,
          Footer: info => {
            return <> {footerData?.totalAvgChargeSummaryMins}</>;
          }
        }
      ]
    },
    {
      Header: "F",
      columns: [
        {
          Header: "Average Charging Area Stop Time",
          accessor: "avgChargeStopMins",
          show: true,
          Footer: info => {
            return <> {footerData?.totalAvgChargeStopMins}</>;
          }
        }
      ]
    },
    {
      Header: "G",
      columns: [
        {
          Header: "Average Charging Area Move Time",
          accessor: "chargingMoveAvgTime",
          show: true,
          Footer: info => {
            return <> {footerData?.totalAvgChargingMoveTime}</>;
          }
        }
      ]
    },
    {
      Header: "Average Move Time",
      accessor: "avgMoveMins",
      show: true,
      Footer: info => {
        return <> {footerData?.totalAvgMoveMins}</>;
      }
    },
    {
      Header: "(H+I)",
      columns: [
        {
          Header: "Avg Stoppage Time",
          accessor: "stopAreaAvgTime",
          show: true,
          Footer: info => {
            return <> {footerData?.totalAvgStopAreaTime}</>;
          }
        }
      ]
    },
    {
      Header: "H",
      columns: [
        {
          Header: "Average Scheduled Stop Time",
          accessor: "avgScheduledStopMins",
          show: true,
          Footer: info => {
            return <> {footerData?.totalAvgScheduledStopMins}</>;
          }
        }
      ]
    },
    {
      Header: "I",
      columns: [
        {
          Header: "Average Unscheduled Stop Time",
          accessor: "avgUnScheduledStopMins",
          show: true,
          Footer: info => {
            return <> {footerData?.totalAvgUnScheduledStopMins}</>;
          }
        }
      ]
    },
    {
      Header: "(J+K+L)",
      columns: [
        {
          Header: "Average Maintanence Duration",
          accessor: "avgMaintanenceTime",
          show: true,
          Footer: info => {
            return <> {footerData?.totalAvgMaintanenceTime}</>;
          }
        }
      ]
    },
    {
      Header: "J",
      columns: [
        {
          Header: "Average Job by Customer",
          accessor: "avgCustomerJobTime",
          show: true,
          Footer: info => {
            return <> {footerData?.totalAvgCustomerJobTime}</>;
          }
        }
      ]
    },
    {
      Header: "K",
      columns: [
        {
          Header: "Average Scheduled Service AMC",
          accessor: "avgServiceTime",
          show: true,
          Footer: info => {
            return <> {footerData?.totalAvgServiceTime}</>;
          }
        }
      ]
    }, {
      Header: "L",
      columns: [
        {
          Header: "Average Breakdown (IPLT)",
          accessor: "avgBdDurationTime",
          show: true,
          Footer: info => {
            return <> {footerData?.totalAvgBdDurationTime}</>;
          },
        }
      ]
    },
    {
      Header: "Avg Gained SOC(%)",
      accessor: "avgSocGained",
      show:true,
      Footer: info => {
        return <> {footerData?.totalAvgSocGained}</>;
      }
    },
    {
      Header: "Avg Energy Efficiency",
      accessor: "avgEnergyEfficiency",
      show: true,
      Footer: info => {
        return <> {footerData?.totalAvgEnergyEfficiency}</>;
      }
    }

  ];
  

  let initialState1 = {};
  var arr = [];



  // Get Data from New Dropdown

  //Export PDF & Excel

  const onChangeXcl = async () => {
    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "routeTrip/getRouteTripSummaryFleetWiseList.xls?regionId=" +
        payload.regionId +
        "&resellerId=" +
        payload.resellerId +
        "&dealerId=" +
        payload.dealerId +
        "&customerId=" +
        payload.customerId +
        "&orgId=" +
        payload.orgId +
        "&vehicleId=" +
        payload.vehicleId +
        "&search=" +
        payload.search +
        "&startDate=" +
        payload.startDate +
        "&endDate=" +
        payload.endDate +
        "&orderdir=" +
        payload.orderdir +
        "&orderColumn=" +
        payload.orderColumn +
        "&sortBy=" +
        payload.sortBy+
        "&page=" +
         payload.page +
         "&size=" +
         payload.size ;

      window.open(url);

      auditPayload.message = "Reports > MonthlySummaryVehicleWise > XLS";
      auditPayload.action = "View";
      auditPayload.featureName = "Monthly Summary";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";

      auditLog(auditPayload);
    } catch (error) {}
  };
  


  return (
    <Box>
      {/* <ReportNewDropDown
        onSubmit={handleSubmit}
        isShowVeh={true}
        displayMonth={true}
      /> */}
      {/* <FilterDropdown getData={onSubmitForm} status={false} monthPicker={true}/> */}
      <StyledCardShadow sx={{ mt: 2 }}>
        <CardContent sx={{ py: 1 }}>
          <DataTableNew
            payload={payload}
            exportWithAll={resellerId === 110224 ? true : false}
            initialState={initialState1}
            columns={columns}
            data={data}
            onChangeXcl={onChangeXcl}
            includeSearchBox={true}
            handleChange={handleSearch}
            styles={{ maxWidth: "100vw" }}
            numberOfStickyColumns={1}
            page={newPage}
            rowsPerPage={rowsPerPage}
            callbackChangePage={pageChange}
            totalElements={totalElements}
            totalPages={rowsPerPage}
            isLoading={isLoadingOut}
            
          />
        </CardContent>
      </StyledCardShadow>
    </Box>
  );
  
};

export default RouteFleetWiseReport;

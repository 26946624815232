import React, { useState, useEffect, useMemo, useRef } from "react";
import { Box } from "@mui/system";
import { DataTable } from "../shared/data_table/DataTable";
import ReportServices from "../../services/reportServices";
import logServices from "../../services/logService";
import { GoDotFill } from "react-icons/go";
import {
  CardHeader,
  CardContent,
  Typography,
  Tooltip,
  Icon,
  Stack,
  Chip,
  Button
} from "@mui/material";
import { Breadcrumb } from "../shared/Breadcrumbs";
import { useTheme } from "@mui/styles";
import MouseOverPopover from "../shared/PopOver";
import { HiFolderArrowDown } from "react-icons/hi2";
import ReportNewDropDown from "../shared/ReportsDropDownNew";
import { moment } from "moment";
import {
  Exportoptions,
  StyledCardShadow,
  StyledPageLayout,
  StyledTab,
  StyledTabBox,
  StyledTableTitle,
  StyledTabsGroup,
  StyledToggleButton,
  StyledToggleButtonGroup,
} from "../../theme/StyledEle";
import addDays from "date-fns/addDays";
import { format } from "date-fns";
import StyledTooltip from "../shared/StyledTooltip";

import {
  useAuditLogMutation,
  useVehicleSummaryMutation,
  useVehicleSummaryDaywiseMutation
} from "../../store/slice/ApiSlice";

const text =
  "This Report gives the vehicle for Daywise data about the vehicle movement for a selected time period. Energy Efficiency is calculated for vehicles that have traveled more than 10km per day";

const VehicleSummaryDaywiseReport = ({ data, payload }) => {
    const [responseData, setResponseData] = useState([]);
    const [faultVehicleId,setFaultVehicleId]=useState(null);
    const [auditLog] = useAuditLogMutation();
    let userData = JSON.parse(localStorage.getItem("userData"));
    let resellerId = userData.resellerId;
    let orgId = userData.orgId;
    let userId = userData.id;
    let orgName = userData.orgName;
    let userName = userData.userName;
    let orgType = userData.userOrgType;
  
    let auditPayload = {
        //Audit Common Payload
        orgId: orgId,
        userId: userId, //User ID
        orgName: orgName, //Org Name
        userName: userName,
    };

    useEffect(async () => {
        auditPayload.message = "Reports >  Vehicle Summary Daywise";
        auditPayload.action = "View";
        auditPayload.featureName = " Vehicle Summary Daywise";
        auditPayload.customerName = "All";
        auditPayload.fleetName = "All";
        auditPayload.result = "Success";
        auditPayload.access = "Portal";
        auditPayload.severity = "INFO";
        auditLog(auditPayload);
    }, []);

    const columnsTractorDaywise = useMemo(
        () => [
          {
            Header: "Created Date",
            accessor: "createdDate",
          },
          {
            Header: "Vehicle Number",
            accessor: "vehicleNo",
            show: false,
          },
          {
            Header: "VIN",
            accessor: "vinNumber",
          },
          {
            Header: "CustomerName",
            accessor: "customerName",
          },
          {
            Header: "Total Hours (hh:mm)",
            columns: [
              {
                Header: "Moving",
                accessor: "moveMinutes",
              },
              {
                Header: "PTO",
                accessor: "workingTime",
              },
              {
                Header: "Stop",
                accessor: "stopMinutes",
              },{
                Header: "Idle",
                accessor: "idleMinutes",
              },{
                Header: "Towing",
                accessor: "towingMinutes",
              },
              {
                Header: "Offline",
                accessor: "offlineMinutes",
              },
            ],
          },
          {
            Header: "HourMeter (hh:mm)",
            columns: [
              {
                Header: "Start HourMeter",
                accessor: "startHourMeterTime",
              },
              {
                Header: "End HourMeter",
                accessor: "endHourMeterTime",
              },
              {
                Header: "Run Time",
                accessor: "hourMeterTime",
              }
            ],
          },
          // {
          //   Header: "Distance Traveled (km)",
          //   columns: [
          //     {
          //       Header: "Start Odometer (km)",
          //       accessor: "startOdometer",
          //     },
          //     {
          //       Header: "End Odometer (km)",
          //       accessor: "endOdometer",
          //     },
          //     {
          //       Header: "Distance Traveled (km)",
          //       accessor: "distanceTraveled",
          //       Cell: ({ row }) => {
          //         if (resellerId === 110224 &&  row.original.distanceTraveled !== 0 ) {
          //           return (
          //             <Box>
          //               <Stack direction={"row"} spacing={1} alignItems={"center"}>
          //                 <Icon
          //                   sx={{
          //                     width: "auto",
          //                     height: "auto",
          //                     display: "flex",
          //                     alignItems: "center",
          //                   }}
          //                   color={
          //                     row.original.distanceTraveled !== 0 && (row.original.distanceTraveled >=
          //                     row.original.targetedDistance)
          //                       ? "success" :  "error" 
          //                   }
          //                 >
          //                   <GoDotFill />
          //                 </Icon>
          //                 <Typography>{row.original.distanceTraveled}</Typography>
          //               </Stack>
          //             </Box>
          //           );
          //         } else {
          //           return row.original.distanceTraveled;
          //         }
          //       },
          //     },
          //   ],
          // },
          {
            Header: (
              <StyledTooltip
                header={
                  resellerId === 110224
                    ? "Energy Consumed (kWh)"
                    : "Energy Consumed (Wh)"
                }
                title={
                  "Total Energy Consumed including all days irrespective of distance traveled"
                }
              />
            ),
            accessor: "energyConsumed",
          },
          {
            Header: "Charging Details",
            columns: [
              {
                Header: "Count",
                accessor: "chargeCount",
                Cell: ({ row }) => {
                  return row.original.chargeCount !== 0
                    ? row.original.chargeCount
                    : "-";
                },
              },
              {
                Header: "Duration (hh:mm)",
                accessor: "chargeMins",
              },
              
          {
            Header: "Start SOC (%)",
            accessor: "startSoc",
          },
          {
            Header: "End SOC (%)",
            accessor: "endSoc",
          },
            ],
          },
          {
            Header: "Maximum Speed (km/h)",
            accessor: "maxSpeed",
          },
        ],
        []
      );

      const columns1Daywise = useMemo(
        () => [
          {
            Header: "Created Date",
            accessor: "createdDate",
          },
          {
            Header: "Vehicle Number",
            accessor: "vehicleNo",
            show: false,
          },
          {
            Header: "VIN",
            accessor: "vinNumber",
          },
          {
            Header: "CustomerName",
            accessor: "customerName",
          },
          {
            Header: "Total Hours (hh:mm)",
            columns: [
              {
                Header: "Moving",
                accessor: "moveMinutes",
              },
              {
                Header: "Stop",
                accessor: "stopMinutes",
              },
              {
                Header: "Idle",
                accessor: "idleMinutes",
              },
              {
                Header: "Offline",
                accessor: "offlineMinutes",
              },
            ],
          },
          {
            Header: "Distance Traveled (km)",
            columns: [
              {
                Header: "Start Odometer (km)",
                accessor: "startOdometer",
              },
              {
                Header: "End Odometer (km)",
                accessor: "endOdometer",
              },
              {
                Header: "Distance Traveled (km)",
                accessor: "distanceTraveled",
                Cell: ({ row }) => {
                  if (resellerId === 110224 &&  row.original.distanceTraveled !== 0 ) {
                    return (
                      <Box>
                        <Stack direction={"row"} spacing={1} alignItems={"center"}>
                          <Icon
                            sx={{
                              width: "auto",
                              height: "auto",
                              display: "flex",
                              alignItems: "center",
                            }}
                            color={
                              row.original.distanceTraveled !== 0 && (row.original.distanceTraveled >=
                              row.original.targetedDistance)
                                ? "success" :  "error" 
                            }
                          >
                            <GoDotFill />
                          </Icon>
                          <Typography>{row.original.distanceTraveled}</Typography>
                        </Stack>
                      </Box>
                    );
                  } else {
                    return row.original.distanceTraveled;
                  }
                },
              },
              {
                Header: "Target Distance (km)",
                accessor: "targetedDistance",
              },
            ],
          },
          {
            Header: (
              <StyledTooltip
                header={
                  resellerId === 110224
                    ? "Energy Consumed (kWh)"
                    : "Energy Consumed (Wh)"
                }
                title={
                  "Total Energy Consumed including all days irrespective of distance traveled"
                }
              />
            ),
            accessor: "energyConsumed",
          },
          {
            Header: "Stop Energy Consumed (kWh)",
            accessor: "stopEnergyConsumedinpark",
          },
          {
            Header: "Move Energy Consumed (kWh)",
            accessor: "movingEnergyConsumed",
          },
    
          {
            Header: (
              <StyledTooltip
                header={
                  resellerId === 110224
                    ? "Energy Efficiency (km/kWh)"
                    : "Energy Efficiency (km/Wh)"
                }
                title={
                  "Energy Efficiency takes into consideration only the days on which the vehicle has traveled more than 10 kms. The formula used is Total Distance Traveled on days that meet above criteria /Total Energy consumed on such days."
                }
              />
            ),
            accessor: "batteryEfficiency",
          },
          {
            Header: (
              <StyledTooltip
                header={
                  resellerId === 110224
                    ? "Energy Efficiency (kWh/km)"
                    : "Energy Efficiency (Wh/km)"
                }
                title={
                  "Energy Efficiency takes into consideration only the days on which the vehicle has traveled more than 10 kms. The formula used is Total Energy consumed on such days / Total Distance Traveled on days that meet above criteria. "
                }
              />
            ),
            accessor: "efficiencyPerKM",
            Cell: ({ row, column, cell }) => {
              const efficeincy = row.original.efficiencyPerKM;
    
              return row.original.efficiencyPerKM !== "" &&
                row.original.efficiencyPerKM !== null &&
                row.original.efficiencyPerKM !== "-"
                ? parseFloat(efficeincy).toFixed(2)
                : "-";
            },
          },
          {
            Header: "Charging Details",
            columns: [
              {
                Header: "Count",
                accessor: "chargeCount",
                Cell: ({ row }) => {
                  return row.original.chargeCount !== 0
                    ? row.original.chargeCount
                    : "-";
                },
              },
              {
                Header: "Duration (hh:mm)",
                accessor: "chargeMins",
              },
              
          {
            Header: "Start SOC (%)",
            accessor: "startSoc",
          },
          {
            Header: "End SOC (%)",
            accessor: "endSoc",
          },
          {
            Header: "Last Charge Time",
            accessor: "lastChargeTime",
          },
          {
            Header: "Max Power At Full SOC (wh)",
            accessor: "powerAtFullSoc",
          },
          {
            Header: "Power At Last SOC (wh)",
            accessor: "powerAtLastSoc",
          },
            ],
          },
          {
            Header: "Alarm (count)",
            columns: [
              {
                Header: "Acceleration",
                accessor: "harshAccelerationCount",
              },
              {
                Header: "Brake",
                accessor: "harshbrakeCount",
              },
              {
                Header: "Fault",
                accessor: "faultCount",
                Cell: ({ row, column, cell }) => {             
                  return (                
                    <Box>
                       {row.original.faultCount}
                    {  row.original.faultCount>0 && <Button
                      onClick={(e) => handleFaultView(row.original.vehicleId)}
                    >
                      Expand
                    </Button>
                  }
                   
                    </Box>
                  );
                }
              },
            ],
          },
          {
            Header: "OverSpeeding",
            columns: [
              {
                Header: "Count",
                accessor: "overSpeedCount",
              },
              {
                Header: "Duration (hh:mm)",
                accessor: "overSpeedDurationstr",
              },
              {
                Header: "Distance (km)",
                accessor: "overSpeedDistance",
              },
              {
                Header: "SOC(%) Consumed",
                accessor: "overSpeedSOCConsumed",
              },
            ],
          },
          {
            Header: "Maximum Speed (km/h)",
            accessor: "maxSpeed",
          },
          // {
          //   Header: (
          //     <StyledTooltip
          //       header={"EV"}
          //       title={"Assumed Rs.10/Electrical unit"}
          //     />
          //   ),
          //   id: "EV",
          //   columns: [
          //     {
          //       // Header: "EB units",
          //       Header: (
          //         <StyledTooltip
          //           header={"EB units"}
          //           title={
          //             "1 unit of electricity=1kWh <br> A kWh is the standard unit used by energy suppliers to calculate electricity bill. One unit refers to the use of 1,000 watts over one hour."
          //           }
          //         />
          //       ),
          //       accessor: "ebUnits1",
          //     },
          //     {
          //       Header: "EV Cost per km (₹)",
          //       accessor: "ebCost1",
          //     },
          //   ],
          // },
          // {
          //   Header: (
          //     <StyledTooltip
          //       header={resellerId === 110224 ? "Diesel" : "Petrol"}
          //       title={"Assumed Rs. 102/Litre"}
          //     />
          //   ),
          //   id: "Petrol",
          //   columns: [
          //     {
          //       Header: (
          //         <StyledTooltip
          //           header={
          //             resellerId === 110224
          //               ? "Diesel  in Litres"
          //               : "Petrol in Litres"
          //           }
          //         />
          //       ),
          //       accessor: "dieselUnits",
          //     },
          //     {
          //       Header: (
          //         <StyledTooltip
          //           header={
          //             resellerId === 110224
          //               ? "Diesel Cost per km (₹)"
          //               : " Petrol Cost per km (₹)"
          //           }
          //         />
          //       ),
          //       accessor: "dieselCost",
          //     },
          //   ],
          // },
          // {
          //   Header: "EV Savings",
          //   columns: [
          //     {
          //       Header: "Savings per km (₹)",
          //       accessor: "savings",
          //     },
          //     {
          //       Header: "Total Savings (₹)",
          //       accessor: "totalSavings",
          //     },
          //   ],
          // },
          {
            Header: "Max Delta Cell Voltage (V)",
            accessor: "maxDeltaCellVolt",
          },{
            Header: "Max Delta Cell Temperature (C)",
            accessor: "maxDeltaCellTemp",
          }
    
        ],
        []
      );

      const columnsSCVDaywise = useMemo(
        () => [
          {
            Header: "Created Date",
            accessor: "createdDate",
          },
          {
            Header: "Vehicle Number",
            accessor: "vehicleNo",
            show: false,
          },
          {
            Header: "VIN",
            accessor: "vinNumber",
          },
          {
            Header: "CustomerName",
            accessor: "customerName",
          },
          {
            Header: "Total Hours (hh:mm)",
            columns: [
              {
                Header: "Moving",
                accessor: "moveMinutes",
              },
              {
                Header: "Stop",
                accessor: "stopMinutes",
              },{
                Header: "Idle",
                accessor: "idleMinutes",
              },
              {
                Header: "Offline",
                accessor: "offlineMinutes",
              },
            ],
          },
          {
            Header: "Distance Traveled (km)",
            columns: [
              {
                Header: "Start Odometer (km)",
                accessor: "startOdometer",
              },
              {
                Header: "End Odometer (km)",
                accessor: "endOdometer",
              },
              {
                Header: "Distance Traveled (km)",
                accessor: "distanceTraveled",
                Cell: ({ row }) => {
                  if (resellerId === 110224 &&  row.original.distanceTraveled !== 0 ) {
                    return (
                      <Box>
                        <Stack direction={"row"} spacing={1} alignItems={"center"}>
                          <Icon
                            sx={{
                              width: "auto",
                              height: "auto",
                              display: "flex",
                              alignItems: "center",
                            }}
                            color={
                              row.original.distanceTraveled !== 0 && (row.original.distanceTraveled >=
                              row.original.targetedDistance)
                                ? "success" :  "error" 
                            }
                          >
                            <GoDotFill />
                          </Icon>
                          <Typography>{row.original.distanceTraveled}</Typography>
                        </Stack>
                      </Box>
                    );
                  } else {
                    return row.original.distanceTraveled;
                  }
                },
              },
              {
                Header: "Target Distance (km)",
                accessor: "targetedDistance",
              },
            ],
          },
          {
            Header: (
              <StyledTooltip
                header={
                  resellerId === 110224
                    ? "Energy Consumed (kWh)"
                    : "Energy Consumed (Wh)"
                }
                title={
                  "Total Energy Consumed including all days irrespective of distance traveled"
                }
              />
            ),
            accessor: "energyConsumed",
          },
          {
            Header: "Stop Energy Consumed (kWh)",
            accessor: "stopEnergyConsumedinpark",
          },
          {
            Header: "Move Energy Consumed (kWh)",
            accessor: "movingEnergyConsumed",
          },
    
          {
            Header: (
              <StyledTooltip
                header={
                  resellerId === 110224
                    ? "Energy Efficiency (km/kWh)"
                    : "Energy Efficiency (km/Wh)"
                }
                title={
                  "Energy Efficiency takes into consideration only the days on which the vehicle has traveled more than 10 kms. The formula used is Total Distance Traveled on days that meet above criteria /Total Energy consumed on such days."
                }
              />
            ),
            accessor: "batteryEfficiency",
          },
          {
            Header: (
              <StyledTooltip
                header={
                  resellerId === 110224
                    ? "Energy Efficiency (kWh/km)"
                    : "Energy Efficiency (Wh/km)"
                }
                title={
                  "Energy Efficiency takes into consideration only the days on which the vehicle has traveled more than 10 kms. The formula used is Total Energy consumed on such days / Total Distance Traveled on days that meet above criteria. "
                }
              />
            ),
            accessor: "efficiencyPerKM",
            Cell: ({ row, column, cell }) => {
              const efficeincy = row.original.efficiencyPerKM;
    
              return row.original.efficiencyPerKM !== "" &&
                row.original.efficiencyPerKM !== null &&
                row.original.efficiencyPerKM !== "-"
                ? parseFloat(efficeincy).toFixed(2)
                : "-";
            },
          },
          {
            Header: "Charging Details",
            columns: [
              {
                Header: "Count",
                accessor: "chargeCount",
                Cell: ({ row }) => {
                  return row.original.chargeCount !== 0
                    ? row.original.chargeCount
                    : "-";
                },
              },
              {
                Header: "Duration (hh:mm)",
                accessor: "chargeMins",
              },
            ],
          },
          // {
          //   Header: "Harsh Usage (count)",
          //   columns: [
          //     {
          //       Header: "Acceleration",
          //       accessor: "harshAccelerationCount",
          //     },
          //     {
          //       Header: "Brake",
          //       accessor: "harshbrakeCount",
          //     },
          //     {
          //       Header: "Cornering",
          //       accessor: "rashTurningCount",
          //     },
          //   ],
          // },
          {
            Header: "OverSpeeding",
            columns: [
              {
                Header: "Count",
                accessor: "overSpeedCount",
              },
              {
                Header: "Duration (hh:mm)",
                accessor: "overSpeedDurationstr",
              },
              {
                Header: "Distance (km)",
                accessor: "overSpeedDistance",
              },
              {
                Header: "SOC(%) Consumed",
                accessor: "overSpeedSOCConsumed",
              },
            ],
          },
          {
            Header: "Maximum Speed (km/h)",
            accessor: "maxSpeed",
          },
          // {
          //   Header: (
          //     <StyledTooltip
          //       header={"EV"}
          //       title={"Assumed Rs.10/Electrical unit"}
          //     />
          //   ),
          //   id: "EV",
          //   columns: [
          //     {
          //       // Header: "EB units",
          //       Header: (
          //         <StyledTooltip
          //           header={"EB units"}
          //           title={
          //             "1 unit of electricity=1kWh <br> A kWh is the standard unit used by energy suppliers to calculate electricity bill. One unit refers to the use of 1,000 watts over one hour."
          //           }
          //         />
          //       ),
          //       accessor: "ebUnits1",
          //     },
          //     {
          //       Header: "EV Cost per km (₹)",
          //       accessor: "ebCost1",
          //     },
          //   ],
          // },
          // {
          //   Header: (
          //     <StyledTooltip
          //       header={resellerId === 110224 ? "Diesel" : "Petrol"}
          //       title={"Assumed Rs. 102/Litre"}
          //     />
          //   ),
          //   id: "Petrol",
          //   columns: [
          //     {
          //       Header: (
          //         <StyledTooltip
          //           header={
          //             resellerId === 110224
          //               ? "Diesel  in Litres"
          //               : "Petrol in Litres"
          //           }
          //         />
          //       ),
          //       accessor: "dieselUnits",
          //     },
          //     {
          //       Header: (
          //         <StyledTooltip
          //           header={
          //             resellerId === 110224
          //               ? "Diesel Cost per km (₹)"
          //               : " Petrol Cost per km (₹)"
          //           }
          //         />
          //       ),
          //       accessor: "dieselCost",
          //     },
          //   ],
          // },
          // {
          //   Header: "EV Savings",
          //   columns: [
          //     {
          //       Header: "Savings per km (₹)",
          //       accessor: "savings",
          //     },
          //     {
          //       Header: "Total Savings (₹)",
          //       accessor: "totalSavings",
          //     },
          //   ],
          // },
    
          {
            Header: "Start SOC (%)",
            accessor: "startSoc",
          },
          {
            Header: "End SOC (%)",
            accessor: "endSoc",
          },
        ],
        []
      );

      const customerColumnsDaywise = useMemo(
        () => [
          {
            Header: "Created Date",
            accessor: "createdDate",
            show: false,
          },
          {
            Header: "Vehicle Number",
            accessor: "vehicleNo",
            show: false,
          },
          {
            Header: "VIN",
            accessor: "vinNumber",
          },
          {
            Header: "CustomerName",
            accessor: "customerName",
          },
          {
            Header: "Total Hours (hh:mm)",
            columns: [
              {
                Header: "Moving",
                accessor: "moveMinutes",
              },
              {
                Header: "Stop",
                accessor: "stopMinutes",
              },
              {
                Header: "Offline",
                accessor: "offlineMinutes",
              },
            ],
          },
          {
            Header: "Distance Traveled (km)",
            columns: [
              {
                Header: "Start Odometer (km)",
                accessor: "startOdometer",
              },
              {
                Header: "End Odometer (km)",
                accessor: "endOdometer",
              },
              {
                Header: "Distance Traveled (km)",
                accessor: "distanceTraveled",
                Cell: ({ row }) => {
                  if (resellerId === 110224 &&  row.original.distanceTraveled !== 0 ) {
                    return (
                      <Box>
                        <Stack direction={"row"} spacing={1} alignItems={"center"}>
                          <Icon
                            sx={{
                              width: "auto",
                              height: "auto",
                              display: "flex",
                              alignItems: "center",
                            }}
                            color={
                              row.original.distanceTraveled !== 0 && (row.original.distanceTraveled >=
                              row.original.targetedDistance)
                                ? "success" :  "error" 
                            }
                          >
                            <GoDotFill />
                          </Icon>
                          <Typography>{row.original.distanceTraveled}</Typography>
                        </Stack>
                      </Box>
                    );
                  } else {
                    return row.original.distanceTraveled;
                  }
                },
              },
              {
                Header: "Target Distance (km)",
                accessor: "targetedDistance",
              },
            ],
          },
          {
            Header: (
              <StyledTooltip
                header={
                  resellerId === 110224
                    ? "Energy Consumed (kWh)"
                    : "Energy Consumed (Wh)"
                }
                title={
                  "Total Energy Consumed including all days irrespective of distance traveled"
                }
              />
            ),
            accessor: "energyConsumed",
          },
          {
            Header: "Stop Energy Consumed (kWh)",
            accessor: "stopEnergyConsumedinpark",
          },
          {
            Header: "Move Energy Consumed (kWh)",
            accessor: "movingEnergyConsumed",
          },
    
          {
            Header: (
              <StyledTooltip
                header={
                  resellerId === 110224
                    ? "Energy Efficiency (km/kWh)"
                    : "Energy Efficiency (km/Wh)"
                }
                title={
                  "Energy Efficiency takes into consideration only the days on which the vehicle has traveled more than 10 kms. The formula used is Total Distance Traveled on days that meet above criteria /Total Energy consumed on such days."
                }
              />
            ),
            accessor: "batteryEfficiency",
          },
          {
            Header: (
              <StyledTooltip
                header={
                  resellerId === 110224
                    ? "Energy Efficiency (kWh/km)"
                    : "Energy Efficiency (Wh/km)"
                }
                title={
                  "Energy Efficiency takes into consideration only the days on which the vehicle has traveled more than 10 kms. The formula used is Total Energy consumed on such days / Total Distance Traveled on days that meet above criteria. "
                }
              />
            ),
            accessor: "efficiencyPerKM",
            Cell: ({ row, column, cell }) => {
              const efficeincy = row.original.efficiencyPerKM;
    
              return row.original.efficiencyPerKM !== "" &&
                row.original.efficiencyPerKM !== null &&
                row.original.efficiencyPerKM !== "-"
                ? parseFloat(efficeincy).toFixed(2)
                : "-";
            },
          },
          {
            Header: "Charging Details",
            columns: [
              {
                Header: "Count",
                accessor: "chargeCount",
                Cell: ({ row }) => {
                  return row.original.chargeCount !== 0
                    ? row.original.chargeCount
                    : "-";
                },
              },
              {
                Header: "Duration (hh:mm)",
                accessor: "chargeMins",
              },
            ],
          },
          {
            Header: "Harsh Usage (count)",
            columns: [
              {
                Header: "Acceleration",
                accessor: "harshAccelerationCount",
              },
              {
                Header: "Brake",
                accessor: "harshbrakeCount",
              },
              {
                Header: "Cornering",
                accessor: "rashTurningCount",
              },
            ],
          },
          {
            Header: "OverSpeeding",
            columns: [
              {
                Header: "Count",
                accessor: "overSpeedCount",
              },
              {
                Header: "Duration (hh:mm)",
                accessor: "overSpeedDurationstr",
              },
              {
                Header: "Distance (km)",
                accessor: "overSpeedDistance",
              },
              {
                Header: "SOC(%) Consumed",
                accessor: "overSpeedSOCConsumed",
              },
            ],
          },
          {
            Header: "Maximum Speed (km/h)",
            accessor: "maxSpeed",
          },
          // {
          //   Header: "EV Savings",
          //   columns: [          
          //     {
          //       Header: "Total Savings (₹)",
          //       accessor: "totalSavings",
          //     },
          //   ],
          // },
    
          {
            Header: "Start SOC (%)",
            accessor: "startSoc",
          },
          {
            Header: "End SOC (%)",
            accessor: "endSoc",
          },
        ],
        []
      );

      const columnsDaywise = useMemo(
        () => [
          {
            Header: "Created Date",
            accessor: "createdDate",
          },
          {
            Header: "Vehicle Number",
            accessor: "vehicleNo",
            show: false,
          },
          {
            Header: "VIN",
            accessor: "vinNumber",
          },
          {
            Header: "CustomerName",
            accessor: "customerName",
          },
          {
            Header: "Total Hours (hh:mm)",
            columns: [
              {
                Header: "Moving",
                accessor: "moveMinutes",
              },
              {
                Header: "Stop",
                accessor: "stopMinutes",
              },
              {
                Header: "Offline",
                accessor: "offlineMinutes",
              },
            ],
          },
          {
            Header: "Distance Traveled (km)",
            columns: [
              {
                Header: "Start Odometer (km)",
                accessor: "startOdometer",
              },
              {
                Header: "End Odometer (km)",
                accessor: "endOdometer",
              },
              {
                Header: "Distance Traveled (km)",
                accessor: "distanceTraveled",
                Cell: ({ row }) => {
                  if (resellerId === 110224 &&  row.original.distanceTraveled !== 0 ) {
                    return (
                      <Box>
                        <Stack direction={"row"} spacing={1} alignItems={"center"}>
                          <Icon
                            sx={{
                              width: "auto",
                              height: "auto",
                              display: "flex",
                              alignItems: "center",
                            }}
                            color={
                              row.original.distanceTraveled !== 0 && (row.original.distanceTraveled >=
                              row.original.targetedDistance)
                                ? "success" :  "error" 
                            }
                          >
                            <GoDotFill />
                          </Icon>
                          <Typography>{row.original.distanceTraveled}</Typography>
                        </Stack>
                      </Box>
                    );
                  } else {
                    return row.original.distanceTraveled;
                  }
                },
              },
              {
                Header: "Target Distance (km)",
                accessor: "targetedDistance",
              },
            ],
          },
          {
            Header: (
              <StyledTooltip
                header={
                  resellerId === 110224
                    ? "Energy Consumed (kWh)"
                    : "Energy Consumed (Wh)"
                }
                title={
                  "Total Energy Consumed including all days irrespective of distance traveled"
                }
              />
            ),
            accessor: "energyConsumed",
          },
          {
            Header: "Stop Energy Consumed (kWh)",
            accessor: "stopEnergyConsumedinpark",
          },
          {
            Header: "Move Energy Consumed (kWh)",
            accessor: "movingEnergyConsumed",
          },
    
          {
            Header: (
              <StyledTooltip
                header={
                  resellerId === 110224
                    ? "Energy Efficiency (km/kWh)"
                    : "Energy Efficiency (km/Wh)"
                }
                title={
                  "Energy Efficiency takes into consideration only the days on which the vehicle has traveled more than 10 kms. The formula used is Total Distance Traveled on days that meet above criteria /Total Energy consumed on such days."
                }
              />
            ),
            accessor: "batteryEfficiency",
          },
          {
            Header: (
              <StyledTooltip
                header={
                  resellerId === 110224
                    ? "Energy Efficiency (kWh/km)"
                    : "Energy Efficiency (Wh/km)"
                }
                title={
                  "Energy Efficiency takes into consideration only the days on which the vehicle has traveled more than 10 kms. The formula used is Total Energy consumed on such days / Total Distance Traveled on days that meet above criteria. "
                }
              />
            ),
            accessor: "efficiencyPerKM",
            Cell: ({ row, column, cell }) => {
              const efficeincy = row.original.efficiencyPerKM;
    
              return row.original.efficiencyPerKM !== "" &&
                row.original.efficiencyPerKM !== null &&
                row.original.efficiencyPerKM !== "-"
                ? parseFloat(efficeincy).toFixed(2)
                : "-";
            },
          },
          {
            Header: "Charging Details",
            columns: [
              {
                Header: "Count",
                accessor: "chargeCount",
                Cell: ({ row }) => {
                  return row.original.chargeCount !== 0
                    ? row.original.chargeCount
                    : "-";
                },
              },
              {
                Header: "Duration (hh:mm)",
                accessor: "chargeMins",
              },
            ],
          },
          {
            Header: "Harsh Usage (count)",
            columns: [
              {
                Header: "Acceleration",
                accessor: "harshAccelerationCount",
              },
              {
                Header: "Brake",
                accessor: "harshbrakeCount",
              },
              {
                Header: "Cornering",
                accessor: "rashTurningCount",
              },
            ],
          },
          {
            Header: "OverSpeeding",
            columns: [
              {
                Header: "Count",
                accessor: "overSpeedCount",
              },
              {
                Header: "Duration (hh:mm)",
                accessor: "overSpeedDurationstr",
              },
              {
                Header: "Distance (km)",
                accessor: "overSpeedDistance",
              },
              {
                Header: "Overspeed Energy Consumed (kWh)",
                accessor: "overSpeedSOCConsumed",
              },
            ],
          },
          {
            Header: "Maximum Speed (km/h)",
            accessor: "maxSpeed",
          },
          // {
          //   Header: (
          //     <StyledTooltip
          //       header={"EV"}
          //       title={"Assumed Rs.10/Electrical unit"}
          //     />
          //   ),
          //   id: "EV",
          //   columns: [
          //     {
          //       // Header: "EB units",
          //       Header: (
          //         <StyledTooltip
          //           header={"EB units"}
          //           title={
          //             "1 unit of electricity=1kWh <br> A kWh is the standard unit used by energy suppliers to calculate electricity bill. One unit refers to the use of 1,000 watts over one hour."
          //           }
          //         />
          //       ),
          //       accessor: "ebUnits1",
          //     },
          //     {
          //       Header: "EV Cost per km (₹)",
          //       accessor: "ebCost1",
          //     },
          //   ],
          // },
          // {
          //   Header: (
          //     <StyledTooltip
          //       header={resellerId === 110224 ? "Diesel" : "Petrol"}
          //       title={"Assumed Rs. 102/Litre"}
          //     />
          //   ),
          //   id: "Petrol",
          //   columns: [
          //     {
          //       Header: (
          //         <StyledTooltip
          //           header={
          //             resellerId === 110224
          //               ? "Diesel  in Litres"
          //               : "Petrol in Litres"
          //           }
          //         />
          //       ),
          //       accessor: "dieselUnits",
          //     },
          //     {
          //       Header: (
          //         <StyledTooltip
          //           header={
          //             resellerId === 110224
          //               ? "Diesel Cost per km (₹)"
          //               : " Petrol Cost per km (₹)"
          //           }
          //         />
          //       ),
          //       accessor: "dieselCost",
          //     },
          //   ],
          // },
          // {
          //   Header: "EV Savings",
          //   columns: [
          //     {
          //       Header: "Savings per km (₹)",
          //       accessor: "savings",
          //     },
          //     {
          //       Header: "Total Savings (₹)",
          //       accessor: "totalSavings",
          //     },
          //   ],
          // },
    
          {
            Header: "Start SOC (%)",
            accessor: "startSoc",
          },
          {
            Header: "End SOC (%)",
            accessor: "endSoc",
          },
        ],
        []
      );

      let initialState1 = {};
      var arr = [];
    
      if (resellerId !== 110224) {
        arr.push("batteryEfficiency");
        arr.push("targetedDistance");
    
        arr.push("stopEnergyConsumedinpark");
        arr.push("overSpeedSOCConsumed");
        arr.push("overSpeedDistance");
        arr.push("customerName");
        arr.push("movingEnergyConsumed");
        initialState1.hiddenColumns = arr;
      }

      const [
        getDayWise, 
        { data: vehicleSummaryDayWiseData, isLoading, isSuccess: dayWise }
    ] = useVehicleSummaryDaywiseMutation();

        //Export PDF & Excel
   
        const onChangeXcl = async () => {
            try {
              
              if(payload.resellerOrgId === 110224){
                var url =
                process.env.REACT_APP_API_BASE_URL +
                "VehicleSummaryReport/VehicleSummaryReportcsv.csv?regionId=" +
                payload.regionId +
                "&resellerOrgId=" +
                payload.resellerOrgId +
                "&dealerId=" +
                payload.dealerId +
                "&customerId=" +
                payload.customerId +
                "&orgId=" +
                payload.orgId +
                "&vehicleId=" +
                payload.vehicleId +
                "&size=" +
                payload.size +
                "&page=" +
                payload.page +
                "&startDate=" +
                payload.startDate +
                "&endDate=" +
                payload.endDate +
                "&selperiod=" +
                payload.selperiod +
                "&orderDir=" +
                payload.sortDir +
                "&sortDir=" +
                payload.sortDir +
                "&search=" +
                payload.search;
              }else if(payload.resellerOrgId === 110050) {
                var url =
                process.env.REACT_APP_API_BASE_URL +
                "VehicleSummaryReport/VehicleSummaryReportThreeWheeler.csv?regionId=" +
                payload.regionId +
                "&resellerOrgId=" +
                payload.resellerOrgId +
                "&dealerId=" +
                payload.dealerId +
                "&customerId=" +
                payload.customerId +
                "&orgId=" +
                payload.orgId +
                "&vehicleId=" +
                payload.vehicleId +
                "&size=" +
                payload.size +
                "&page=" +
                payload.page +
                "&startDate=" +
                payload.startDate +
                "&endDate=" +
                payload.endDate +
                "&selperiod=" +
                payload.selperiod +
                "&orderDir=" +
                payload.sortDir +
                "&sortDir=" +
                payload.sortDir +
                "&search=" +
                payload.search;
              }else if(payload.resellerOrgId === 114663) {
                var url =
                process.env.REACT_APP_API_BASE_URL +
                "VehicleSummaryReport/VehicleSummaryReportTractor.csv?regionId=" +
                payload.regionId +
                "&resellerOrgId=" +
                payload.resellerOrgId +
                "&dealerId=" +
                payload.dealerId +
                "&customerId=" +
                payload.customerId +
                "&orgId=" +
                payload.orgId +
                "&vehicleId=" +
                payload.vehicleId +
                "&size=" +
                payload.size +
                "&page=" +
                payload.page +
                "&startDate=" +
                payload.startDate +
                "&endDate=" +
                payload.endDate +
                "&selperiod=" +
                payload.selperiod +
                "&orderDir=" +
                payload.sortDir +
                "&sortDir=" +
                payload.sortDir +
                "&search=" +
                payload.search;
              }else if(payload.resellerOrgId === 114613) {
                var url =
                process.env.REACT_APP_API_BASE_URL +
                "VehicleSummaryReport/VehicleSummaryReportSCV.csv?regionId=" +
                payload.regionId +
                "&resellerOrgId=" +
                payload.resellerOrgId +
                "&dealerId=" +
                payload.dealerId +
                "&customerId=" +
                payload.customerId +
                "&orgId=" +
                payload.orgId +
                "&vehicleId=" +
                payload.vehicleId +
                "&size=" +
                payload.size +
                "&page=" +
                payload.page +
                "&startDate=" +
                payload.startDate +
                "&endDate=" +
                payload.endDate +
                "&selperiod=" +
                payload.selperiod +
                "&orderDir=" +
                payload.sortDir +
                "&sortDir=" +
                payload.sortDir +
                "&search=" +
                payload.search;
              }else {
                var url =
                process.env.REACT_APP_API_BASE_URL +
                "VehicleSummaryReport/vehicleSummaryReportAll.xls?regionId=" +
                payload.regionId +
                "&resellerOrgId=" +
                payload.resellerOrgId +
                "&dealerId=" +
                payload.dealerId +
                "&customerId=" +
                payload.customerId +
                "&orgId=" +
                payload.orgId +
                "&vehicleId=" +
                payload.vehicleId +
                "&size=" +
                payload.size +
                "&page=" +
                payload.page +
                "&startDate=" +
                payload.startDate +
                "&endDate=" +
                payload.endDate +
                "&selperiod=" +
                payload.selperiod +
                "&orderDir=" +
                payload.sortDir +
                "&search=" +
                payload.search;
              }
            
                window.open(url);
              
             auditPayload.message = 'Reports >  Vehicle Summary Daywise > XLS';
             auditPayload.action = 'View';
             auditPayload.featureName = 'Vehicle Summary Daywise';
             auditPayload.customerName = 'All';
             auditPayload.fleetName = 'All';
             auditPayload.result = 'Success';
             auditPayload.access = 'Portal';
             auditPayload.severity = 'INFO';
             auditLog(auditPayload);

             fetch(url, {
                headers: {
                  Authorization:
                    "Bearer " + JSON.parse(window.localStorage.getItem("jwToken")),
                },
              }) // FETCH BLOB FROM IT
                .then((response) => {
                  response.blob();
                })
                .then((blob) => {})
                .catch((err) => {});
            } catch (error) {}
          };

          const onChangePDF = async () => {
  
            try {
                   var url =
                process.env.REACT_APP_API_BASE_URL +
                "VehicleSummaryReport/vehicleSummaryReportAll.Pdf?regionId=" +
                payload.regionId +
                "&resellerOrgId=" +
                payload.resellerOrgId +
                "&dealerId=" +
                payload.dealerId +
                "&customerId=" +
                payload.customerId +
                "&orgId=" +
                payload.orgId +
                "&vehicleId=" +
                payload.vehicleId +
                "&size=" +
                payload.size +
                "&page=" +
                payload.page +
                "&startDate=" +
                payload.startDate +
                "&selperiod=" +
                payload.selperiod +
                "&endDate=" +
                payload.endDate +
                "&orderDir=" +
                payload.sortDir +
                "&search=" +
                payload.search;
                window.open(url);
      
        
              auditPayload.message = 'Reports > Vehicle Summary Daywise > PDF';
              auditPayload.action = 'View';
              auditPayload.featureName = ' Vehicle Summary Daywise';
              auditPayload.customerName = 'All';
              auditPayload.fleetName = 'All';
              auditPayload.result = 'Success';
              auditPayload.access = 'Portal';
              auditPayload.severity = 'INFO';
              auditLog(auditPayload);
            } catch (error) {}
          };

            //Search Text
            const handleSearch = async (searchText) => {
              payload.search = searchText;
              if (payload.search.length >= 3) {
                getDayWise(payload);
              } else if (payload.search.length === 0) {
                getDayWise(payload);
              }
            };
          
            useEffect(() => {
              if (data && data.data && data.data.content) {
                setResponseData(data.data.content);
              } else {
                setResponseData([]); // Set empty array if data is undefined or content is null
              }
            }, [data]);

            const handleFaultView = (faultVehId) => {
              setFaultVehicleId(faultVehId);
            } 


                        return(
                          <Box>
                              <StyledCardShadow sx={{ mt: 2 }}>
                                  <CardContent sx={{ py: 1 }}>
                                      <DataTable
                                          payload={payload}
                                          exportWithAll={false}
                                          initialState={initialState1}
                                          isLoading={isLoading}
                                          columns={resellerId === 114663
                                            ? columnsTractorDaywise
                                            : resellerId === 110050
                                            ? columns1Daywise
                                            : resellerId === 114613
                                            ? columnsSCVDaywise
                                            : orgType === 3
                                            ? customerColumnsDaywise
                                            : columnsDaywise}
                                          data={responseData}
                                          styles={{ maxWidth: "100vw" }}
                                          numberOfStickyColumns={1}
                                          onChangeXcl={onChangeXcl}
                                          onChangePdf={onChangePDF}
                                          includeSearchBox={false}
                                          handleChange={handleSearch}
                                          includePagination={false}
                                          // page={newPage}
                                          // rowsPerPage={31}
                                          // callbackChangePage={pageChange}
                                          // totalElements={totalElements}
                                          // totalPages={totalPages}
                                      />
                                  </CardContent>
                              </StyledCardShadow>
                          </Box>
                        );
}
export default VehicleSummaryDaywiseReport;
import React, { useMemo, useState, useEffect, useCallback } from "react";
import { DataTable } from "../shared/data_table/NestedTable";
import { useGetCapturedGeoZoneHistoryDetailsMutation,useGetCapturedGeoZoneHistoryAllDetailsMutation, useUpdateLoadMutation,useUpdateLoadNewMutation } from "../../store/slice/RouteTripSlice/RouteTripSlice";
import { StyledDropDown } from "../../theme/StyledEle";
import { Alert, Box, Grid, Icon, InputBase, MenuItem, Snackbar, Stack, Table, TableBody, TableContainer, TableHead, TableRow, Typography, useTheme } from "@mui/material";
import { PiMinusSquareLight, PiPlusSquareLight } from "react-icons/pi";
import { BodyTableCell, HeaderTableCell, StyledTableHead, StyledTableRow } from "../shared/data_table/StyledEle";
import { TableBodyCell } from "mui-datatables";
import { useDispatch } from "react-redux";
import { snackService } from "../../store/slice/ControlsSlice";
import StyledSnackBar from "../../Utils/StyledSnackBar";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const CollapseRouteTripHistory = ({ routeId }) => {
  const theme = useTheme();
const location = useLocation();
    let pathname = location.pathname.split("/")[1].split("#")[0];


  const [
    getCapturedGeozonesHistory,
    {
      data: getCapturedGeozoneData,
      isLoading: capturedGeozoneDataLoading,
      isSuccess: capturedGeozoneDataSuccess,
    },
  ] = useGetCapturedGeoZoneHistoryDetailsMutation() ;

  const [
    getCapturedAllGeozonesHistory,
    {
      data: getCapturedAllGeozoneData,
      isLoading: capturedAllGeozoneDataLoading,
      isSuccess: capturedAllGeozoneDataSuccess,
    },
  ] = useGetCapturedGeoZoneHistoryAllDetailsMutation() ;

  const [responseData, setResponseData] = useState([]);

  const userData = JSON.parse(localStorage.getItem('userData'));
  const orgId = userData.orgId;



  const fetchSlotDetails = async () => {
    var request = {};
    request.tripId = routeId?.routeTripId;
    request.vehicleId = routeId?.vehicleId;
    if(routeId?.zoneSummary.length > 0){
      setResponseData(routeId?.zoneSummary);
    }else{
      if(pathname=='route'){
        const responseData = await getCapturedGeozonesHistory(request);
        setResponseData(responseData?.data?.data);
      }else {
        const responseData = await getCapturedAllGeozonesHistory(request);
        setResponseData(responseData?.data?.data);
      }
      
    }
   
  };

  useEffect(() => {
    fetchSlotDetails();
  }, [routeId]);

     //Export PDF & Excel
     const onChangeXcl = async () => {
      try {
        var url =
          process.env.REACT_APP_API_BASE_URL +
          "routeTrip/getCapturedGeoZoneDetailsXLS.xls?tripId=" +
          routeId?.id +
          "&vehicleId=" +
          routeId?.vehicleId +
          "&vehicleNo=" +
          routeId?.vehicleNo +
          "&startDate=" +
          routeId?.startTime +
          "&endDate=" +
          routeId?.endTime +
          "&orgId=" +
          orgId ;
          
        window.open(url);
  
       //  auditPayload.message = "Reports > View Route > XLS";
       //  auditPayload.action = "View";
       //  auditPayload.featureName = "View Route";
       //  auditPayload.customerName = "All";
       //  auditPayload.fleetName = "All";
       //  auditPayload.result = "Success";
       //  auditPayload.access = "Portal";
       //  auditPayload.severity = "INFO";
       //  auditLog(auditPayload);
  
        fetch(url, {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(window.localStorage.getItem("jwToken")),
          },
        }) // FETCH BLOB FROM IT
          .then((response) => {
            response.blob();
          })
          .then((blob) => {})
          .catch((err) => {});
      } catch (error) {}
    };

    const dispatch = useDispatch();
    const isSuccess = useSelector((state) => state.controls.alert);
  const [alert, setAlert] = useState({show:false, message:null, code:0});
   const [updateLoadConditions, {data:updateLoadData, isLoading:updateLoadLoading, isSuccess:updateLoadSuccess}] = useUpdateLoadMutation();
   const [updateLoadNewConditions, {data:updateLoadNewData, isLoading:updateLoadNewLoading, isSuccess:updateLoadNewSuccess}] = useUpdateLoadNewMutation();
    //Load On Change
    const loadOnChange = (event, row) => {
      updateLoad(routeId, row, event.target.value, 'status');
    }

    const loadActivityOnChange = (event, row) => {
      updateLoad(routeId, row, event.target.value, 'activity');
    }

    let message = false

    const updateLoad = async (routeId, row, value, type) => {
      let payload = {
        "tripId": routeId.routeTripId,
        "vehicleId": routeId.vehicleId,
        "sequenceNo": row.original.sequenceNumber,
        "loadCondition": value,
        "type":type
      }

      if(pathname=='route'){
            const updateLoadRes = await updateLoadConditions(payload);
          
          if(updateLoadRes?.data !== undefined && updateLoadRes?.data !== null){
            //dispatch(snackService([updateLoadRes?.data.resultMessage, updateLoadRes?.data.resultCode, true]));
            setAlert({show:true, message:updateLoadRes?.data.resultMessage, code:updateLoadRes?.data.resultCode})
          }else{
            //dispatch(snackService(['Request Failed', 0, true]));
            setAlert({show:true, message:'Request Failed', code:0})
          }
      }else {
        const updateLoadRes = await updateLoadNewConditions(payload);
      
          if(updateLoadRes?.data !== undefined && updateLoadRes?.data !== null){
            setAlert({show:true, message:updateLoadRes?.data.resultMessage, code:updateLoadRes?.data.resultCode})
          }else{
            setAlert({show:true, message:'Request Failed', code:0})
          }
      }
      
     
      setTimeout(() => {
        setAlert({show:false, message:null, code:0})
      },5000)
    }



    const columns = useMemo(
      () => [
        // {
        //   Header: "S.No",
        //   accessor: "sequenceNumber",
        //   width:'80',
        //   Cell:({row}) => {
        //       console.log('row', row);
        //       return row.original.zoneType === 'within'
        //   }
        // },
        {
          Header: "Geozone",
          accessor: "geoZoneName",
          width:'280',
          // Cell:({row}) => (
          //   <Stack direction={'row'} justifyContent={'space-between'} {...row.getToggleRowExpandedProps()} sx={{width:'100%'}}>
          //       <Typography variant="body2">{row.original.geoZoneName}</Typography>
          //       <Icon sx={{display:'flex'}} color="info">{row.isExpanded ? <PiMinusSquareLight  /> : <PiPlusSquareLight /> } </Icon>
          //   </Stack>
          //   // <span {...row.getToggleRowExpandedProps()}>
          //   //     {row.isExpanded ? '👇' : '👉'}
          //   // </span>
          // )
        },
        {
          Header: "Category",
          accessor: "zoneCategory",    
        },
        {
          Header: "Radius (m)",
          accessor: "radius",
        },
        {
          Header: "Entry Time",
          accessor: "entryTime",
    
        },
        {
          Header: "Exit Time",
          accessor: "exitTime",
          
        },
        {
          Header: "Planned Duration",
          accessor: "plannedDuration",
        },
        {
          Header: "Actual Duration",
          accessor: "actualDuration",
    
        },
        {
          Header: "Duration Difference",
          accessor: "durationDifference",    
        },

        {
          Header: "Running Time (hh:mm)",
          accessor: "moveMins",
        },
        {
          Header: "Start Odometer (km)",
          accessor: "startOdometer",
        },
        {
          Header: "End Odometer (km)",
          accessor: "endOdometer",
        },
        {
          Header: "Distance Traveled (km)",
          accessor: "distanceTraveled",
        },,
        {
          Header: "Max Speed (km/h)",
          accessor: "maxSpeed",
        },
        {
          Header: "Average Speed (km/h)",
          accessor: "avgSpeed",
        },
        {
          Header: "Energy Consumed (kWh)",
          accessor: "energyConsumed",
        },
        {
          Header: "Entry SOC (%)",
          accessor: "startSOC",
        },
        {
          Header: "Exit SOC (%)",
          accessor: "endSOC",
        },

        {
          Header: "Charging Mins",
          accessor: "chargingMins",
        },
        {
          Header: "Energy Gained (kWh)",
          accessor: "gainedSOC",
        },
        {
          Header: "Battery Temperature Rise (℃)",
          accessor: "riseInTempZoneIn",
        },
        {
          Header: "Max Battery Temp (℃)",
          accessor: "maxTemp",
        },
        {
          Header: "Avg Battery Temp (℃)",
          accessor: "avgBatteryTempZoneIn",
        },
        {
          Header: "Max Battery Current (A)",
          accessor: "maxCurrent",
        },
        {
          Header: "Load Condition",
          accessor: "",
          Cell:({row}) => {
            return (

              row.original.zoneType === 'within' && (row.original.zoneCategory === 'Loading' || row.original.zoneCategory === 'UnLoading') ?
              <StyledDropDown size="small" defaultValue={row.original.loadCondition !== "-" ? row.original.loadCondition : "-1" } fullWidth sx={{
                '& .MuiOutlinedInput-notchedOutline':{
                  borderWidth:'0px !important'
                }}}
                onChange={(event) => loadOnChange(event, row)}
              >
                  <MenuItem value={'-1'}>Select</MenuItem>
                  <MenuItem value={'empty'}>Empty</MenuItem>
                  <MenuItem value={'semiLoad'}>Semi Load</MenuItem>
                  <MenuItem value={'fullLoad'}>Full Load</MenuItem>
                  <MenuItem value={'overLoad'}>Overload</MenuItem>
              </StyledDropDown> : '-'
            )
          }
        },
      ],
      []
    );

    const columnsNewLogic = useMemo(
      () => [
        // {
        //   Header: "S.No",
        //   accessor: "sequenceNumber",
        //   width:'80',
        //   Cell:({row}) => {
        //       console.log('row', row);
        //       return row.original.zoneType === 'within'
        //   }
        // },
        {
          Header: "Geozone",
          accessor: "geoZoneName",
          width:'280',
          // Cell:({row}) => (
          //   <Stack direction={'row'} justifyContent={'space-between'} {...row.getToggleRowExpandedProps()} sx={{width:'100%'}}>
          //       <Typography variant="body2">{row.original.geoZoneName}</Typography>
          //       <Icon sx={{display:'flex'}} color="info">{row.isExpanded ? <PiMinusSquareLight  /> : <PiPlusSquareLight /> } </Icon>
          //   </Stack>
          //   // <span {...row.getToggleRowExpandedProps()}>
          //   //     {row.isExpanded ? '👇' : '👉'}
          //   // </span>
          // )
        },
        {
          Header: "Category",
          accessor: "zoneCategory",    
        },
        {
          Header: "Radius (m)",
          accessor: "radius",
        },
        {
          Header: "Entry Time",
          accessor: "entryTime",
    
        },
        {
          Header: "Exit Time",
          accessor: "exitTime",
          
        },
        {
          Header: "Planned Duration",
          accessor: "plannedDuration",
        },
        {
          Header: "Actual Duration",
          accessor: "actualDuration",
    
        },
        {
          Header: "Duration Difference",
          accessor: "durationDifference",    
        },
        {
          Header: "Running Time (hh:mm)",
          accessor: "moveMins",
        },
        {
          Header: "Stop Time (hh:mm)",
          accessor: "stopMins",
        },
        {
          Header: "Start Odometer (km)",
          accessor: "startOdometer",
        },
        {
          Header: "End Odometer (km)",
          accessor: "endOdometer",
        },
        {
          Header: "Distance Traveled (km)",
          accessor: "distanceTraveled",
        },,
        {
          Header: "Max Speed (km/h)",
          accessor: "maxSpeed",
        },
        {
          Header: "Average Speed (km/h)",
          accessor: "avgSpeed",
        },
        {
          Header: "Energy Consumed (kWh)",
          accessor: "energyConsumed",
        },
        {
          Header: "Entry SOC (%)",
          accessor: "startSOC",
        },
        {
          Header: "Exit SOC (%)",
          accessor: "endSOC",
        },

        {
          Header: "Charging Mins",
          accessor: "chargingMins",
        },
        {
          Header: "Energy Gained (kWh)",
          accessor: "gainedSOC",
        },
        {
          Header: "Battery Temperature Rise (℃)",
          accessor: "riseInTempZoneIn",
        },
        {
          Header: "Max Battery Temp (℃)",
          accessor: "maxTemp",
        },
        {
          Header: "Avg Battery Temp (℃)",
          accessor: "avgBatteryTempZoneIn",
        },
        {
          Header: "Max Battery Current (A)",
          accessor: "maxCurrent",
        },
        {
          Header: "Load Condition",
          accessor: "",
          Cell:({row}) => {
            return (

              row.original.zoneType === 'within' && (row.original.zoneCategory === 'Loading' || row.original.zoneCategory === 'UnLoading') ?
              <StyledDropDown size="small" defaultValue={row.original.loadCondition !== "-" ? row.original.loadCondition : "-1" } fullWidth sx={{
                '& .MuiOutlinedInput-notchedOutline':{
                  borderWidth:'0px !important'
                }}}
                onChange={(event) => loadOnChange(event, row)}
              >
                  <MenuItem value={'-1'}>Select</MenuItem>
                  <MenuItem value={'empty'}>Empty</MenuItem>
                  <MenuItem value={'semiLoad'}>Semi Load</MenuItem>
                  <MenuItem value={'fullLoad'}>Full Load</MenuItem>
                  <MenuItem value={'overLoad'}>Overload</MenuItem>
              </StyledDropDown> : '-'
            )
          }
        },
      ],
      []
    );

    let tableCelStyle = {
      padding:'6px',
      borderRight:`.5px solid ${theme.palette.divider}`,
    }
    let tableBodyCelStyle = {
      padding:'6px',
      borderRight:`.5px solid ${theme.palette.divider}`,
      background:theme.palette.background.paper,
    }

    //Expand 
    const renderSubRowCompoent = useCallback(({row}) => {
      return (
        <Box>
            <TableContainer sx={{marginLeft:'80px'}}>
                <Table
                  sx={{ overflowX: "scroll", borderCollapse: "separate"}}
                >
                    <StyledTableHead sx={{background:'#e0e0e0'}}>
                        <tr>
                            <td style={tableCelStyle}>Total Duraion</td>
                            <td style={tableCelStyle}>Running Time (hh:mm)</td>
                            <td style={tableCelStyle}>Start Odometer (km)</td>
                            <td style={tableCelStyle}>End Odometer (km)</td>
                            <td style={tableCelStyle}>Distance Traveled (km)</td>
                            <td style={tableCelStyle}>Max Speed (km/h)</td>
                            <td style={tableCelStyle}>Average Speed (km/h)</td>
                            <td style={tableCelStyle}>Energy Consumed (kWh)</td>
                            <td style={tableCelStyle}>Entry SOC (%)</td>
                            <td style={tableCelStyle}>Exit SOC (%)</td>
                            <td style={tableCelStyle}>Charging Mins</td>
                            <td style={tableCelStyle}>Energy Gained (kWh)</td>
                            <td style={tableCelStyle}>Battery Temperature Rise (℃)</td>
                            <td style={tableCelStyle}>Max Battery Temp (℃)</td>
                            <td style={tableCelStyle}>Avg Battery Temp (℃)</td>
                            <td style={tableCelStyle}>Max Battery Current (A)</td>
                        </tr>
                    </StyledTableHead>
                    <TableBody className="nestedBody">
                        <tr>
                            <td style={tableBodyCelStyle}>{row.original.actualDurationZoneBetween}</td>
                            <td style={tableBodyCelStyle}>{row.original.moveMinsZoneBetween}</td>
                            <td  style={tableBodyCelStyle}>{row.original.startCanOdometerZoneBetween}</td>
                            <td style={tableBodyCelStyle}>{row.original.endCanOdometerZoneBetween}</td>
                            <td style={tableBodyCelStyle}>{row.original.canDistanceTraveledZoneBetween}</td>
                            <td style={tableBodyCelStyle}>{row.original.maxCanSpeedZoneBetween}</td>
                            <td style={tableBodyCelStyle}>{row.original.avgCanSpeedZoneBetween}</td>
                            <td style={tableBodyCelStyle}>{row.original.energyConsumedZoneBetween}</td>
                            <td style={tableBodyCelStyle}>{row.original.startSOCZoneBetween}</td>
                            <td style={tableBodyCelStyle}>{row.original.endSOCZoneBetween}</td>
                            <td style={tableBodyCelStyle}>{row.original.chargingSummaryMinsZoneBetween}</td>
                            <td style={tableBodyCelStyle}>{row.original.gainedSocSummaryMinsZoneBetween}</td>
                            <td style={tableBodyCelStyle}>{row.original.riseInTempZoneBetween}</td>
                            <td style={tableBodyCelStyle}>{row.original.maxBatteryTempZoneBetween}</td>
                            <td style={tableBodyCelStyle}>{row.original.avgBatteryTempZoneBetween}</td>
                            <td style={tableBodyCelStyle}>{row.original.maxBatteryCurrentZoneBetween}</td>
                        </tr>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
      )
    },[]) 


  
  return (
    <>
      <DataTable
      columns={pathname=='route'?columns:columnsNewLogic}
      data={responseData}
      styles={{ maxWidth: "calc(100% - 65% )", overflowX:'hidden'}}
      numberOfStickyColumns={1}
      onChangeXcl={onChangeXcl}
      includeExportButton={false}
      renderSubRowCompoent={renderSubRowCompoent}
      />
      {
        <Snackbar open={alert.show}
        anchorOrigin={{ vertical:'top', horizontal:'center' }}
        >
          <Alert
            severity={alert.code === 0 ? 'success' : 'error'}
          >
            <Typography>{alert.message}</Typography>
           
          </Alert>
        </Snackbar>
      }
    </>
  );
};

export default CollapseRouteTripHistory;

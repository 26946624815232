import AvlDataReport from "../components/Reports/AvlDataReport";
import EVDataReport from "../components/Reports/EVDataReport";
import GeoZoneDetail from "../components/Reports/GeoZoneDetail";
import IdleReport from "../components/Reports/IdleReport";
import OverSpeedReport from "../components/Reports/OverSpeedReport";
import SinocastelData from "../components/Reports/SinocastelData";
import Stoppage from "../components/Reports/Stoppage";
import TravelDetailReport from "../components/Reports/TravelDetailReport";
import TravelSummaryReport from "../components/Reports/TravelSummary";
import TripRangeReport from "../components/Reports/TripRangeReport";
import TripReport from "../components/Reports/TripReport";
import TripSummary from "../components/Reports/TripSummary";
import TyrePressureReport from "../components/Reports/TyrePressureReport.js";
import AccountSettings from "../components/account/accountSettings";
import Alerts from "../components/alerts/index";
import Device from "../components/device/index";
import Driver from "../components/driver";
import Feature from "../components/feature/index";
import GeoZone from "../components/geozone/GeoZone";
import Organization from "../components/organization/index";
import ProfileSettings from "../components/profile-settings/index";
import Rolesettings from "../components/rolesettings/index";
import ServiceAlert from "../components/service-alerts";
import Sim from "../components/sim/index";
import User from "../components/user/index";
import Vehicles from "../components/vehicle";
import viewAlert from "../components/view-alerts/view";
import SidebarLayout from "../layout/SidebarLayout";
// import ManualCanDataReport from "../components/Reports/VehicleDataReport";
import OrgSettings from "../components/orgSettings/orgSettings";
import UserLog from "../components/Logs/UserLog";
import SendLog from "../components/Logs/SendLog";
import SignIn1 from "../components/login/forgotPassword";
import SignIn2 from "../components/login/userActivation";
import OrgSetting from "../components/orgSettings";
import OTA from "../components/OTA/OTA";
import KycLog from "../components/KYC-LOGS";
import HelpDesk from "../components/helpdesk/index";
import Tasks from "../components/OTA/tasks";
import FileUpload from "../components/OTA/fileUpload";
import SimOnBoard from "../components/simOnBoard";
import DeviceOnBoard from "../components/deviceOnBoard";
import VinLog from "../components/Logs/VinLog";
import GeoFence from "../components/Reports/GeoFenceReport";
import PageNotFound from "../components/page-not-found/PageNotFound";
import FaultCode from "../components/Reports/FaultCodeReport";
import ChargingReport from "../components/Reports/ChargingReport";
import MonthlySummary from "../components/Reports/MonthlySummaryReport";
import ThemeEditor from "../settings/ThemeEditor/ThemeEditor";
import VehicleSummaryReport from "../components/Reports/VehicleSummaryReport";
import Dashboard from "../components/dashboard/Dashboard";
import LoginLatest from "../components/login/LoginLatest";
import PlaybackAnimate from "../components/Playback/Index";
import NewDashboard from "../components/dashboard/NewDashboard";
import Main from "../components/NewTracking/Main";
import CreateHelpdeskSettings from "../components/helpdesk/HelpdeskSettings";
import PlaybackMain from "../components/Playback/PlaybackMain";
import AirtelLog from "../components/Logs/AirtelLog";
import DeviceOTA from "../components/DEVICE-OTA";
import DeviceDataReport from "../components/Reports/DeviceDataReport";
import ListRouteTrip from "../components/route/ListViewRoute"
import RouteTrip from "../components/route/RouteTrip.js";
import TargetDistance from "../components/TargetDistance/index.js";
import TryPlayback from "../components/Playback/TryPlayback.js";
import TestDriveReport from "../components/Reports/TestDriveReport";
import GeoZoneMap from "../components/NewGeoZone/GeoZoneMap.js";
import CellLog from "../components/Logs/CellVoltLog.js";
import TripReportHistory from "../components/Reports/TripReportHistory.js";
import ListBanner from "../components/Banner/ListBanner.js";
import CreateFotaForm from "../components/FOTA/index.js";
import AuditLog from "../components/Logs/AuditLog";
import FeedLog from "../components/Logs/Index";
import VehicleDataReport from "../components/Reports/VehicleDataReportIndex";
import Dealer from "../components/dealer";
//import CreateFotaForm from "../components/FOTA/index.js";
// import ListBanner from "../components/Banner/ListBanner.js";
import Instrumentation from "../components/Instrumentation";
import LicenseReport from "../components/LicenseReport/licenseReport";

import ListRouteTripHistory from "../components/Reports/RouteTripHistoryReport.js";

import ChargingReportMain from "../components/Reports/ChargingReportMain.js";

import VehicleLatestDetailMain from "../components/Reports/VehicleLatestDetailMain.js";
import UtilizationReport from "../components/Reports/UtilizationReport.js";
import VehicleBreakDownReport from "../components/Reports/VehicleBreakDownReport.js";

import DmsLog from "../components/Logs/DmsLog.js";
import ImbalanceLog from "../components/Logs/ImbalanceLog.js";
import ServiceSlaReport from "../components/ServiceSla/ServiceSLA.js";
import VehicleEfficiencyReport from "../components/Reports/VehicleEfficiencyReport.js";
import RouteSummaryReport from "../components/Reports/RouteSummaryReport.js";

export default [
  {
    component: SidebarLayout,
    isProtected: true,
    children: [
      {
        path: "/",
        //component: GoogleMap,
        component: Main,
      },
      {
        path: "mapviews",
        //component: GoogleMap,
        component: Main,
      },
      {
        path: "Newtracking",
        //component: GoogleMap,
        component: Main,
      },
      {
        path: "dashboard",
        //component: Dashboard,
        component: NewDashboard,
      },
      {
        path: "playback",
        //component: PlayBack,
        component: TryPlayback,
        //component: PlaybackMain,
      },
      {
        path: "geozone",
        component: GeoZone,
        //component: GeoZoneMap,
      },
      {
        path: "overSpeed",
        component: OverSpeedReport,
      },
      {
        path: "travelDetail",
        component: TravelDetailReport,
      },
      {
        path: "travelSummary",
        component: TravelSummaryReport,
      },
      {
        path: "idleSummary",
        component: IdleReport,
      },
      {
        path: "monthlySummary",
        component: MonthlySummary,
      },
      {
        path: "geoZoneDetail",
        component: GeoZoneDetail,
      },
      {
        path: "stoppage",
        component: Stoppage,
      },
      {
        path: "tripSummary",
        component: TripSummary,
      },
      {
        path: "tripReport",
        component: TripReport,
      },
      {
        path: "AvlDataReport",
        component: AvlDataReport,
      },
      {
        path: "sinocastelData",
        component: SinocastelData,
      },
      {
        path: "tripRangeReport",
        component: TripRangeReport,
      },
      {
        path: "tyrePressureReport",
        component: TyrePressureReport,
      },
      {
        path: "evDataReport",
        component: EVDataReport,
      },
      {
        path: "VehicleData",
        component: VehicleDataReport,
      },
      {
        path: "GeoFenceReport",
        component: GeoFence,
      },
      {
        path: "FaultCode",
        component: FaultCode,
      },
      {
        path: "VehicleBreakDownReport",
        component: VehicleBreakDownReport,
      },
      {
        path: "ChargingReport",
        component: ChargingReportMain,
      },
      {
        path: "VehicleSummary",
        component: VehicleSummaryReport,
      },
      {
        path: "organization",
        component: Organization,
      },
      {
        path: "user",
        component: User,
      },
      {
        path: "device",
        component: Device,
      },
      {
        path: "feature",
        component: Feature,
      },
      {
        path: "rolesettings",
        component: Rolesettings,
      },
      {
        path: "vehicle",
        component: Vehicles,
      },
      {
        path: "driver",
        component: Driver,
      },
      {
        path: "accountSettings",
        component: AccountSettings,
      },
      {
        path: "profileSettings",
        component: ProfileSettings,
      },
      {
        path: "themeeditor",
        component: ThemeEditor,
      },
      {
        path: "sim",
        component: Sim,
      },
      {
        path: "viewAlerts",
        component: viewAlert,
      },
      {
        path: "serviceAlert",
        component: ServiceAlert,
      },
      {
        path: "alerts",
        component: Alerts,
        //component: NewAssingUsers,
      },
      {
        path: "orgSettings",
        component: OrgSetting,
      },
      {
        path: "AuditLog",
        component: AuditLog,
      },
      {
        path: "UserLog",
        component: UserLog,
      },
      {
        path: "VinLog",
        component: VinLog,
      },
      {
        path: "SendLog",
        component: SendLog,
      },
      {
        path: "FeedLog",
        component: FeedLog,
      },
      {
        path: "helpdesk",
        component: HelpDesk,
      },
      {
        path: "dealer",
        component: Dealer,
      },
      {
        path: "OTA",
        component: OTA,
      },
      {
        path: "tasks",
        component: Tasks,
      },
      {
        path: "fileUpload",
        component: FileUpload,
      },
      {
        path: "pageNotFound",
        component: PageNotFound,
      },
      {
        path: "simOnBoard",
        component: SimOnBoard,
      },
      {
        path: "deviceOnBoard",
        component: DeviceOnBoard,
      },
      {
        path: "KycLog",
        component: KycLog,
      },
      {
        path: "helpdeskSettings",
        component: CreateHelpdeskSettings,
      },
      {
        path: "AirtelLog",
        component: AirtelLog,
      },
      {
        path: "deviceOTA",
        component: DeviceOTA,
      },
      {
        path: "deviceDataReport",
        component: DeviceDataReport,
      },{
        path: "route",
        component: RouteTrip,
      },
      {
        path: "targettedDistanceSetting",
        component: TargetDistance,
      },
      {
        path: "testDriveReport",
        component: TestDriveReport,
      },
      {
        path: "CellLog",
        component: CellLog,
      },      
      {
        path: "tripHistoryReport",
        component: TripReportHistory,
      },
      {

        path: "fota",
        component: CreateFotaForm,
      },{
        path: "banner",
        component: ListBanner,
      },   
      {
        path: "licenseReport",
        component: LicenseReport,
      },
      {
        path: "routeTripHistoryReport",
        component: ListRouteTripHistory,
      },
      {
        path: "instrumentation",
        component: Instrumentation,
      },{
        path: "VehicleLatestDetailReport",
        component: VehicleLatestDetailMain,
      },{
        path: "UtilizationReport",
        component: UtilizationReport 
      },
      {
        path: "dmslog",
        component: DmsLog,
      },
      {
        path: "ImbalanceLog",
        component: ImbalanceLog,
      },
      {
        path: "ServiceSlaReport",
        component: ServiceSlaReport,
      },
      {
        path:"VehicleEfficiecnyReport",
        component: VehicleEfficiencyReport
      },
      {
        path: "routeTripSummary",
        component: RouteSummaryReport,
      },
      {
        path: "routegeozone",
        component: RouteTrip,
      }

    ],
  },
  // {
  //   path: "forgotPassword",
  //   component: SignIn1,
  // },
  // {
  //   path: "userActivation",
  //   component: SignIn2,
  // },
  // {
  //   path: "/",
  //   component: Login,
  // },
];

import React, { useState, useEffect, useMemo, useRef } from "react";
import { Box } from "@mui/system";
import { DataTable } from "../shared/data_table/DataTable";
import ReportServices from "../../services/reportServices";
import logServices from "../../services/logService";
import { GoDotFill } from "react-icons/go";
import {
  CardHeader,
  CardContent,
  Typography,
  Tooltip,
  Icon,
  Stack,
  Chip,
  Button
} from "@mui/material";
import { Breadcrumb } from "../shared/Breadcrumbs";
import { useTheme } from "@mui/styles";
import MouseOverPopover from "../shared/PopOver";
import { HiFolderArrowDown } from "react-icons/hi2";
import ReportNewDropDown from "../shared/ReportsDropDownNew";
import { moment } from "moment";
import {
  Exportoptions,
  StyledCardShadow,
  StyledPageLayout,
  StyledTab,
  StyledTabBox,
  StyledTableTitle,
  StyledTabsGroup,
  StyledToggleButton,
  StyledToggleButtonGroup,
} from "../../theme/StyledEle";
import addDays from "date-fns/addDays";
import { format } from "date-fns";
import StyledTooltip from "../shared/StyledTooltip";
import {
  useAuditLogMutation,
  useVehicleSummaryMutation,
  useVehicleSummaryDaywiseMutation
} from "../../store/slice/ApiSlice";
import FilterDropdown from "../NewDropdown/FilterDropdown";
import { useLocation, useNavigate } from "react-router-dom";
import FormDialog from "../../Utils/FormDialog";
import ThreeWheelerFaultPopup from "./ThreeWheelerFaultPopup";
import VehicleSummaryVehiclewiseReport from "../../components/Reports/VehicleSummaryVehiclewiseReport";
import VehicleSummaryDaywiseReport from "../../components/Reports/VehicleSummaryDaywiseReport";

const text =
  "This Report gives the Summarized data about the vehicle movement for a Vehicle-wise or Day-wise. Energy Efficiency is calculated for vehicles that have traveled more than 10km per day";

const VehicleSummaryReport = () => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;
  let orgType = userData.userOrgType;

  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, //User ID
    orgName: orgName, //Org Name
    userName: userName,
  };

    const [hideDetails, setHidedetails] = useState("vehicleWise");
    const [alignment, setAlignment] = React.useState("vehicleWise");
    const [responseData, setResponseData] = React.useState([]);
      //Vegicle Wise data success;
      const [vehicleData, setVehicleData] = useState([]);
      const [newPage, setNewPage] = useState(0);
      const [rowsPerPage, setRowsPerPage] = useState(25);
      const [totalElements, setTotalElements] = useState(5);
      const [totalPages, setTotalPages] = useState(1);
      const [auditLog] = useAuditLogMutation();
      const [faultVehicleId,setFaultVehicleId]=useState(null);
    
    const handleAlignment = (event, newAlignment) => {
      setResponseData([]);
      if (newAlignment !== null) {
        setAlignment(newAlignment);
      }
    };
    const handleChange = (event) => {
      setHidedetails(event.target.value);
    };

    const [getOverSpeed, { data: vehicleSummaryData, isLoading, isSuccess }] =
    useVehicleSummaryMutation();

  const [
    getDayWise, 
    { data: vehicleSummaryDaywiseData }
  ] = useVehicleSummaryDaywiseMutation();


  useEffect(async () => {
    auditPayload.message = "Reports >  Vehicle Summary";
    auditPayload.action = "View";
    auditPayload.featureName = " Vehicle Summary";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditLog(auditPayload);
  }, []);

  //Montra Tap
  const [value, setValue] = useState(0);
  const [dashboardUrl, setDashboardUrl] = useState(null); // Only for montra
  let iframeReload = useRef();

  // Get Data from New Dropdown


    const [payload, setPayload] = useState(null);
    const [rawPayload, setRawPayload] = useState(null);
  let commonPayload = {};
  const onSubmitForm = (data) => {
    setRawPayload(data);
    setNewPage(0);
    setRowsPerPage(25);
    commonPayload.resellerOrgId = resellerId;
    commonPayload.regionId = data.regionId;
    commonPayload.dealerId = data.dealerId;
    commonPayload.customerId = data.customerId;
    commonPayload.orgId = data.fleetId !== undefined ? data.fleetId : data.orgId;
    commonPayload.vehicleId = data.vehicleId !=null ?data.vehicleId:-1;
    commonPayload.search = "";
    commonPayload.startDate = data.fromDate !== undefined ? data.fromDate : data.startDate;
    commonPayload.endDate = data.toDate !== undefined ? data.toDate : data.endDate ;
//  commonPayload.sortDir = !isToggleOn ? "desc" : "asc";
    commonPayload.sortDir = alignment === "vehicleWise" ? "desc" : "asc";
    commonPayload.page = 0;
    commonPayload.size = alignment === "vehicleWise" ? 25 : 31;
    if(commonPayload?.selectedPeriodName==="Last 7 Days"){
      commonPayload.selperiod = 7;
    }else{
      commonPayload.selperiod = data.selectedPriod !== undefined ? data.selectedPriod : data.selperiod;
    }   
    //Service Call
    dashboardGen(data);
    setPayload(commonPayload);

    if (alignment === "vehicleWise") {
      getOverSpeed(commonPayload);
      auditPayload.message = "Reports > Vehicle Summary > Vehicle Wise>"+data.regionName+" > "+data.dealerName+" > "+data.customerName+" > "+data.fleetName+" > "+data.vehicleName+" > "+data.selectedPeriodName+"("+data.fromDate+"-"+data.toDate+")"+" > "+"Submit";
    }else{
     // getDayWise(commonPayload);
     getDayWise(payload);
     auditPayload.message = "Reports > Vehicle Summary > Day Wise>"+data.regionName+" > "+data.dealerName+" > "+data.customerName+" > "+data.fleetName+" > "+data.vehicleName+" > "+data.selectedPeriodName+"("+data.fromDate+"-"+data.toDate+")"+" > "+"Submit";
    }

    
    auditPayload.action = "Submit";
    auditPayload.featureName = "Vehicle Summmary Report";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);

  };

  // React.useEffect(() => {
  //   console.log('alignment',alignment);
  //   if (alignment === "vehicleWise") {
  //     if (commonPayload !== null) {
  //      getOverSpeed(commonPayload);
  //     }
  //   }else{
  //    // getDayWise(commonPayload);
  //    if (commonPayload !== null) {
  //     getDayWise(commonPayload);
  //    }
  //   }
  // },[alignment]);

   const dashboardGen = (formData) => {

    var regionId = "";
    var customerId = "";
    var dealerId = "";
    var fleetId = "";
    var vehicleId = "";
    if (formData !== null) {
      if (formData?.regionId !== "-1") {
        regionId = '"REGION_ID"=' + formData?.regionId + " and ";
      } else {
        regionId = '"REGION_ID"!=5' + "and";
      }

      if (formData?.dealerId !== "-1") {
        dealerId = '"DEALER_ID"=' + formData?.dealerId + " and ";
      } else {
        dealerId = "";
      }

      if (formData?.customerId !== "-1") {
        customerId = '"CUSTOMER_ID"=' + formData?.customerId + " and ";
      } else {
        customerId = "";
      }

      if (formData?.fleetId !== "-1" && payload?.orgId !== '-1') {
        let fleet = formData?.fleetId !== undefined ? formData?.fleetId : payload?.orgId
        fleetId = '"org_id"=' + fleet + " and ";
      } else {
        fleetId = "";
      }
      if (formData?.vehicleId !== "-1") {
        vehicleId = '"vehicle_id"=' + formData?.vehicleId + " and ";
      } else {
        vehicleId = "";
      }
    }

    //Set URL
    setDashboardUrl(
      process.env.REACT_APP_PROD_ENV === 'true' ?
      "https://analytics.zoho.in/open-view/353263000000054294?ZOHO_CRITERIA=" + 
      regionId +
      dealerId +
      customerId +
      fleetId +
      vehicleId +
      " RESELLER_ID =" +
      resellerId + ' AND "VW_zoho_monthly_vs_DW"."z_created_date" BETWEEN ' + `'${formData?.fromDate !== undefined ? formData?.fromDate :payload?.startDate }'` + " AND " + `'${formData?.toDate !== undefined ? formData?.toDate : payload?.endDate}'` +
      ""
      :
        "https://analytics.zoho.in/open-view/353263000001330822?ZOHO_CRITERIA=" +
      regionId +
      dealerId +
      customerId +
      fleetId +
      vehicleId +
      " RESELLER_ID =" +
      resellerId + ' AND "VW_zoho_monthly_vs_DW"."z_created_date" BETWEEN ' + `'${formData?.fromDate !== undefined ? formData?.fromDate :payload?.startDate }'` + " AND " + `'${formData?.toDate !== undefined ? formData?.toDate : payload?.endDate}'` +
      ""
    )

   
  }

   //Search Text
   const handleSearch = async (searchText) => {
        setNewPage(0);
        setRowsPerPage(25);
        payload.page = 0;
        payload.rowsPerPage = 25;
       payload.search = searchText;
       if(payload.search.length >= 3){
         getOverSpeed(payload);
       }else if(payload.search.length === 0){
         getOverSpeed(payload);
       }
       
   };
   //Page Change
   const pageChange = (newPage, rowsPerPage) => {
    setRowsPerPage(rowsPerPage);
    payload.size = rowsPerPage;
    if(totalElements < rowsPerPage * newPage){
      setNewPage(0)
      payload.page = 0;
      getOverSpeed(payload);
    }else{
      setNewPage(newPage);
      payload.page = newPage;
      getOverSpeed(payload);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setResponseData([]);
      // Handle data for OverSpeed (or the default mutation)
      if (vehicleSummaryData.data !== null) {
         setResponseData(vehicleSummaryData.data.content);
        setTotalElements(vehicleSummaryData.data.totalElements);
        setTotalPages(vehicleSummaryData.data.totalPages);
      }else{
        setResponseData([]);
      } 
    }
  }, [isSuccess,vehicleSummaryData]);


  const navigate = useNavigate();
  const location = useLocation();
  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  //   var commonPayload={...payload};
  //   if(newValue === 1){
  //     navigate('#montraDaywise');
  //     onSubmitForm(commonPayload);
  //   }else{
  //     navigate(location.pathname);
  //     onSubmitForm(commonPayload);
  //   }
  // };

  const handleFaultView = (faultVehId) => {
    setFaultVehicleId(faultVehId);
    navigate('#view');
  } 

  return (
    <StyledPageLayout>
      <Breadcrumb
        mainDiv="Reports"
        subDiv="Vehicle Summary Report"
        includePopup={true}
      ></Breadcrumb>
      <MouseOverPopover text={text} title={"Vehicle Summary Report"} />
      <Box>
        { alignment === "vehicleWise" &&  (<FilterDropdown
          getData={onSubmitForm}
          status={false}
          allowDays = {1}
          time = {false}
          timeWithVehicle={false}
          last6Month={true}
          allowedMonth = {resellerId === 110224 ? 6 : 3}
          thisMonth={location.hash === "#montraDaywise"?false:true}
          currentMonth={location.hash === "#montraDaywise"?false:true}
        />)}

      { alignment === "DayWise" && (<FilterDropdown
          getData={onSubmitForm}
          status={false}
          allowDays = {31}
          time = {false}
          timeWithVehicle={false}
          last6Month={true}
          allowedMonth = {resellerId === 110224 ? 6 : 3}
          setToday={false}
        isShowAll={false}
        thisMonth={true}
        currentMonth={true}
        isToggleOn={true}
      />)}



                <Box sx={{ m: 0 }}>
                  
                  {value === 0 && (
                    <StyledToggleButtonGroup
                      value={alignment}
                      exclusive
                      onChange={handleAlignment}
                      onClick={handleChange}
                      size="small"
                      sx={{ mt: 2 }}
                    >
                      <StyledToggleButton value={"vehicleWise"}>
                        Vehicle Wise
                      </StyledToggleButton>
                      <StyledToggleButton value={"DayWise"}>Day Wise</StyledToggleButton>
                    </StyledToggleButtonGroup>
                  )}
                </Box>


         {value === 0 && (
            <>
           {hideDetails === "vehicleWise" && (
            <VehicleSummaryVehiclewiseReport
              data={responseData}
              payload={payload}
              isLoadingOut={isLoading}
              handleSearch={handleSearch}
              pageChange={pageChange}
              newPage={newPage}
              rowsPerPage={rowsPerPage}
              totalElements={totalElements}
              totalPages={totalPages}
            />
          )} 
          {hideDetails === "DayWise" && (
            <VehicleSummaryDaywiseReport
              data={vehicleSummaryDaywiseData}
              payload={payload}
            />
          )}
        </>
       )}

         {/* Daywise Chart */}

        {resellerId === 110224 && false && (
          <StyledTabBox>
            <StyledTabsGroup
              value={value}
              variant="scrollable"
              onChange={handleChange}
              sx={{
                "& .MuiTabs-flexContainer": {
                  flexDirection: "row",
                },
              }}
            >
              <StyledTab label={"Vehicle Summary"} key={1} />
              {
                orgType === 2 &&  <StyledTab label={"Vehicle Summary - Daywise"} key={2} />
              }
            </StyledTabsGroup>
          </StyledTabBox>
        )}
        <StyledCardShadow sx={{ mt: 2 }}>

          {value === 1 && (
            <CardContent sx={{ py: 1 }}>
              <Box sx={{ height: "100vh" }}>
                <iframe
                  className="iframe"
                  src={dashboardUrl}
                  width="100%"
                  height="98%"
                  ref={iframeReload}
                ></iframe>
              </Box>
            </CardContent>
          )}
        </StyledCardShadow>
      </Box>
      {location.hash === '#view' && faultVehicleId>0 && <FormDialog title={'Fault Details'} padding={false} size="md">
        <ThreeWheelerFaultPopup payload={payload} vehicleId={faultVehicleId}/>
    </FormDialog>}
    </StyledPageLayout>
  );
};

export default VehicleSummaryReport;

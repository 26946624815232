import { Box, Chip, Grid, Paper, Stack, Typography, useTheme } from '@mui/material'
import React from 'react'

function MovingCard(props) {
    const theme = useTheme();
    const { trailsData } = props;
    const userData = JSON.parse(localStorage.getItem('userData'));
    let resellerId = userData.resellerId;

  return (
    trailsData.length > 0 && trailsData !== null && trailsData.map((item, index) => {
        return (
        <Box elevation={0} sx={{mt:.5, border:`.5px solid ${item?.vehicleStatus === '0' ? theme.palette.error.main : item?.vehicleStatus === '1' ? theme.palette.success.main :  item?.vehicleStatus === '5' ? theme.palette.info.main : item?.vehicleStatus === '3' ? theme.palette.primary.main : item?.vehicleStatus === '7' ? '#9130B2' : 'default'}`,my:1, px:.8, borderRadius:'10px'}} key={index}>
        <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant='body2' sx={{fontSize:'13px',}}>{item?.startDate}</Typography>
            <Chip size='small'  
                color={item?.vehicleStatus === '0' ? 'error' : item?.vehicleStatus === '1' ? 'success' : item?.vehicleStatus === '5' ? 'info' : item?.vehicleStatus === '3' ? 'primary' : 'default' } 
                label={item?.vehicleStatus === '0' ? 'Stop' : item?.vehicleStatus === '1' ? 'Moving' : item?.vehicleStatus === '5' ? 'PTO' : item?.vehicleStatus === '3' ? 'Idle' : item?.vehicleStatus === '7' ? 'Towing' : 'default'} 
                sx={{borderRadius:'0px 0px 10px 10px', fontSize:'12px', color:item?.vehicleStatus === '7' ? '#9130B2':'default'}}/>
        </Stack>
        <Grid container sx={{mt:.8, pb:.8}}>
            { resellerId !== 114663 && (<Grid item lg={4}>
                <Typography variant='body2' color={'text.secondary'} sx={{fontSize:'12px', mb:.2}}>Odometer</Typography>
                <Typography variant='body2' sx={{fontSize:'13px',fontWeight:'500'}}>{item?.odometer}</Typography>
            </Grid>)}
            { resellerId === 114663 && (<Grid item lg={4}>
                <Typography variant='body2' color={'text.secondary'} sx={{fontSize:'12px', mb:.2}}>HourMeter</Typography>
                <Typography variant='body2' sx={{fontSize:'13px',fontWeight:'500'}}>{item?.hourMeter}</Typography>
            </Grid>)}
            <Grid item lg={4}>
                <Typography variant='body2' color={'text.secondary'} sx={{fontSize:'12px', mb:.2}}>SOC (%)</Typography>
                <Typography variant='body2' sx={{fontSize:'13px',fontWeight:'500'}}>{item?.soc}</Typography>
            </Grid>
            <Grid item lg={4}>
                <Typography variant='body2' color={'text.secondary'} sx={{fontSize:'12px', mb:.2}}>Speed (km/h)</Typography>
                <Typography variant='body2' sx={{fontSize:'13px',fontWeight:'500'}}>{item?.speed}</Typography>
            </Grid>
        </Grid>
        </Box>
        )
    })
    
  )
}

export default MovingCard
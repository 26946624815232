  import React, { useState, useEffect, useMemo } from "react";
import { Box } from "@mui/system";
import { DataTable } from "../shared/data_table/DataTable";
import ReportServices from "../../services/reportServices";
import { CardContent, Typography } from "@mui/material";
import { Breadcrumb } from "../shared/Breadcrumbs";
import { useTheme } from "@mui/styles";
import MouseOverPopover from "../shared/PopOver";
import ReportNewDropDown from "../shared/ReportsDropDownNew";
import { ButtonAlign } from "../shared/button/ButtonAlign";
import { StyledCardShadow, StyledPageLayout } from "../../theme/StyledEle";
import addDays from "date-fns/addDays";
import { format } from "date-fns";
import { Button } from "@mui/material";
import logServices from "../../services/logService";
import {
  useAuditLogMutation,
  useChargingMutation,
} from "../../store/slice/ApiSlice";
import FilterDropdown from "../NewDropdown/FilterDropdown";

const text =
  "This report shows instances when the vehicle's battery was charging for more than 5 minutes. The charging is determined by continuous increase in battery state-of-charge (SOC %).";

const ChargingReport = () => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;
  let roleId = userData.roleId; 

  const [responseData, setResponseData] = useState([]);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [auditLog] = useAuditLogMutation();
  const theme = useTheme();
  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, //User ID
    orgName: orgName, //Org Name
    userName: userName,
  };
  useEffect(async () => {
    auditPayload.message = "Reports > Charging";
    auditPayload.action = "View";
    auditPayload.featureName = "Charging Report";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditLog(auditPayload);
  }, []);


  const columns = React.useMemo(() => {
    const commonColumns = [
      {
        Header: "Vehicle Number",
        accessor: "vehicleno",
        width:180,
      },
      {
        Header: "Customer Name",
        accessor: "customerName",
        width:120,
      },
      {
        Header: "GPS Location",
        accessor: "location",
        width:350,
        Cell: ({ row, column, cell }) => {
          const refAddres = React.useRef(row);
          var refLink = React.useRef(row);
          var getAddressBtn = React.useRef(row);
          var toggleAddressBtn = React.useRef(row);
          const getAddress = async (e) => {
            if (refAddres.current.innerText === "") {
              var request = {};
              request.latLng = e.target.value;
              // rowid = e.target.id;
              const responseData = (
                await ReportServices.getGeocodeAddress(request)
              ).data;
              refAddres.current.innerText = responseData;
              if (responseData !== "") {
                refLink.current.style.display = "none";
                refAddres.current.style.display = "block";
                toggleAddressBtn.current.style.display = "block";
                getAddressBtn.current.style.display = "none";
                toggleAddressBtn.current.innerText = "LatLng";
              }
            }
          };

          //Address toggle
          const ToggleAdderss = () => {
            if (refAddres.current.style.display === "block") {
              refAddres.current.style.display = "none";
              refLink.current.style.display = "block";
              toggleAddressBtn.current.innerText = "Address";
            } else {
              refAddres.current.style.display = "block";
              refLink.current.style.display = "none";
              toggleAddressBtn.current.innerText = "LatLng";
            }
          };
          return (
              <Box
                className="addresslink"
                id={row.original.location}
                sx={{ display: "flex", alignItems: "center" }}
              >
                  
                <Button
                  onClick={(e) => getAddress(e)}
                  value={row.original.location}
                  ref={getAddressBtn}
                  size="small"
                >
                  Address
                </Button>
                <Button
                  onClick={(e) => ToggleAdderss(e)}
                  ref={toggleAddressBtn}
                  sx={{ display: "none" }}
                >
                  LatLng
                </Button>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${row.original.location}`}
                  target="_blank"
                  className="latlink"
                  ref={refLink}
                  id={row.original.location}
                >
                  {row.original.location}
                </a>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${row.original.location}`}
                  target="_blank"
                  className="address"
                  ref={refAddres}
                  id={row.original.location}
                  key={row.original.location}
                ></a>
              </Box>
    
          );
        },
      },
      {
        Header: "Charging Zone",
        accessor: "zoneName",
      },
      {
        Header: "Zone Category",
        accessor: "zoneCategory",
      },
      {
        Header: "Start Time",
        accessor: "startTime",
      },
      {
        Header: "End Time",
        accessor: "endTime",
      },
      {
        Header: resellerId === 110224 ? "Charging Time (minutes)" : "Charging Time (hh:mm)",
        accessor: "chargingmins",
      },
      {
        Header: "Start SOC (%)",
        accessor: "sstartsoc",
      },
      {
        Header: "End SOC (%)",
        accessor: "sendsoc",
      },
      {
        Header: "Gained SOC (%)",
        accessor: "gainSoc",
      },
      {
        Header:
          resellerId === 110224 ? "Start Energy (kWh)" : "Start Energy (Wh)",
        accessor: "stEnergy",
      },
      {
        Header: resellerId === 110224 ? "End Energy (kWh)" : "End Energy (Wh)",
        accessor: "edEnergy",
      },
      {
        Header:
          resellerId === 110224 ? "Battery Energy gained (kWh)" : "Battery Energy gained (Wh)",
        accessor: "senergyConsumed",
      },
      // {
      //   Header: resellerId === 110224 ? "Charging Current (A)" : "Max Battery Current (A)",
      //   accessor: "maxBtCur",
      // },
      {
        Header: "Max Battery Temp (ºC)",
        accessor: "smaxBtTemp",
      },
      {
        Header: "Charging Type",
        accessor: "chargingType",
      },
      {
        Header: "Charging Status",
        accessor: "chargeStatus",
      },
    ];
  
    if (resellerId === 110224) {
      const additionalColumns = [
        {
          Header: "Average charging voltage (V)",
          accessor: "maxVoltage",
          width:150,
        },
        {
          Header: "Charging Time Above Threshold (35 °C)",
          accessor: "tempThresholdDuration",
          width:150,
        },
        {
          Header: "Charging Efficiency",
          accessor: "chargingEfficiency",
          width:150,
        },
        {
          Header: "Average charging current (A)",
          accessor: "averageCurrent",
          width:150,
        },
        {
          Header: "Average Voltage (V)",
          accessor: "averageVoltage",
          width:150,
        },
        {
          Header: "Energy Consumed (kWh)",
          accessor: "energyConsumed",
        },
        {
          Header: "Charging Unit Consumed (kWh)",
          accessor: "chargingUnitConsumed",
        },
      ];
  
      return [...commonColumns, ...additionalColumns];
    }
  
    return commonColumns;
  }, [resellerId]);





  const columns1 = useMemo(
    () => [
      {
        Header: "Vehicle Number",
        accessor: "vehicleno",
        width:180,
      },
      {
        Header: "GPS Location",
        accessor: "location",
        width:350,
        Cell: ({ row, column, cell }) => {
          const refAddres = React.useRef(row);
          var refLink = React.useRef(row);
          var getAddressBtn = React.useRef(row);
          var toggleAddressBtn = React.useRef(row);
          const getAddress = async (e) => {
            if (refAddres.current.innerText === "") {
              var request = {};
              request.latLng = e.target.value;
              // rowid = e.target.id;
              const responseData = (
                await ReportServices.getGeocodeAddress(request)
              ).data;
              refAddres.current.innerText = responseData;
              if (responseData !== "") {
                refLink.current.style.display = "none";
                refAddres.current.style.display = "block";
                toggleAddressBtn.current.style.display = "block";
                getAddressBtn.current.style.display = "none";
                toggleAddressBtn.current.innerText = "LatLng";
              }
            }
          };

          //Address toggle
          const ToggleAdderss = () => {
            if (refAddres.current.style.display === "block") {
              refAddres.current.style.display = "none";
              refLink.current.style.display = "block";
              toggleAddressBtn.current.innerText = "Address";
            } else {
              refAddres.current.style.display = "block";
              refLink.current.style.display = "none";
              toggleAddressBtn.current.innerText = "LatLng";
            }
          };
          return (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                className="addresslink"
                id={row.original.location}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Button
                  onClick={(e) => getAddress(e)}
                  value={row.original.zoneName}
                  ref={getAddressBtn}
                >
                  Address
                </Button>
                <Button
                  onClick={(e) => ToggleAdderss(e)}
                  ref={toggleAddressBtn}
                  sx={{ display: "none" }}
                >
                  LatLng
                </Button>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${row.original.location}`}
                  target="_blank"
                  className="latlink"
                  ref={refLink}
                  id={row.original.location}
                >
                  {row.original.location}
                </a>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${row.original.location}`}
                  target="_blank"
                  className="address"
                  ref={refAddres}
                  id={row.original.location}
                  key={row.original.location}
                ></a>
              </Box>
            </Box>
          );
        },
      },
      {
        Header: "Start Time",
        accessor: "startTime",
      },
      {
        Header: "End Time",
        accessor: "endTime",
      },
      {
        Header: "Charging Time (hh:mm)",
        accessor: "chargingmins",
      },
      {
        Header: "Start SOC (%)",
        accessor: "sstartsoc",
      },
      {
        Header: "End SOC (%)",
        accessor: "sendsoc",
      },
      {
        Header: "Gained SOC (%)",
        accessor: "gainSoc",
      },
      // {
      //   Header:
      //     resellerId === 110224 ? "Start Energy (kWh)" : "Start Energy (Wh)",
      //   accessor: "stEnergy",
      // },
      // {
      //   Header: resellerId === 110224 ? "End Energy (kWh)" : "End Energy (Wh)",
      //   accessor: "edEnergy",
      // },
      // {
      //   Header:
      //     resellerId === 110224 ? "Energy Gained (kWh)" : "Energy Gained (Wh)",
      //   accessor: "senergyConsumed",
      // },
      // {
      //   Header: "Max Battery Current (A)",
      //   accessor: "maxBtCur",
      // },
      // {
      //   Header: "Max Battery Temp (ºC)",
      //   accessor: "smaxBtTemp",
      // },
    ],
    []
  );

  let initialState1 = {};
  var arr = [];



  if (resellerId !== 110224) {
    arr.push("chargingType");
    arr.push("zoneName");
    arr.push("customerName");
    arr.push("zoneCategory");
    arr.push("energyConsumed");
    initialState1.hiddenColumns = arr;
  } 
  if (resellerId == 110224 && roleId != 17){
    arr.push("energyConsumed");
    initialState1.hiddenColumns = arr;
  }

  if (resellerId !== 114613) {
    arr.push("chargeStatus");
    initialState1.hiddenColumns = arr;
  } 

  // if( resellerId === 110224 && roleId !== 17){
  //   arr.push("maxBtCur");
  //   arr.push("stEnergy");
  //   arr.push("edEnergy");
  //   initialState1.hiddenColumns= arr;
  // }else{
  // arr.push("maxBtCur");
  // arr.push("stEnergy");
  // arr.push("edEnergy");
  // initialState1.hiddenColumns= arr;

  // }

  // Get Data from New Dropdown
  const [getOverSpeed, { data: oversppedData, isLoading, isSuccess }] =
    useChargingMutation();
  let commonPayload = {};
  // const [exportOpt, setExportOpt] = useState(false);

  const onSubmitForm = (data) => {
    // if(data.regionId ==="-1" && data.dealerId ==="-1" && data.customerId ==="-1" && data.fleetId ==="-1" && data.vehicleId === '-1' && data.selectedPriod ===2){
    //   setExportOpt(false);
    // }else if(data.regionId ==="-1" && data.dealerId ==="-1" && data.customerId ==="-1" && data.fleetId ==="-1" && data.vehicleId === '-1' && data.selectedPriod ===3){
    //   setExportOpt(false);
    // }else{
    //   setExportOpt(true);
    // }
    setNewPage(0);
    setRowsPerPage(25);
    commonPayload.reselId = resellerId;
    commonPayload.regionId = data.regionId;
    commonPayload.dealerId = data.dealerId;
    commonPayload.custId = data.customerId;
    commonPayload.orgId = data.fleetId;
    commonPayload.vehicleId = data.vehicleId;
    commonPayload.search = "";
    commonPayload.startDate = data.fromDate;
    commonPayload.endDate = data.toDate;
    commonPayload.orderDir = "desc";
    commonPayload.page = newPage;
    commonPayload.size = rowsPerPage;
    commonPayload.chargingType = data.chargeType
    //Service Call
    setPayload(commonPayload);
    getOverSpeed(commonPayload);

    auditPayload.message = "Reports > Charging Report > "+data.regionName+" > "+data.dealerName+" > "+data.customerName+" > "+data.fleetName+" > "+data.vehicleName+" > "+data.selectedPeriodName+"("+data.fromDate+"-"+data.toDate+")"+" > "+"Submit";
    auditPayload.action = "Submit";
    auditPayload.featureName = "Charging Report";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
  };
  //Export PDF & Excel
  const [payload, setPayload] = useState(null);
  const onChangeXcl = async () => {
    try {
      var custId=payload.custId===null?"-1":payload.custId;
      var url =
        process.env.REACT_APP_API_BASE_URL + (resellerId === 114613 ?"ChargingReport/chargingreportScv.csv?startDate=":
        "ChargingReport/chargingreportXLS.xls?startDate=") +
        payload.startDate +
        "&endDate=" +
        payload.endDate +
        "&search=" +
        payload.search +
        "&orgId=" +
        payload.orgId +
        "&vehicleId=" +
        payload.vehicleId +
        "&reselId=" +
        payload.reselId +
        "&regionId=" +
        payload.regionId +
        "&dealerId=" +
        payload.dealerId +
        "&custId=" +
        custId +
        "&chargingType=" +
        payload.chargingType +
        "&roleId=" +
        roleId;
      window.open(url);

      auditPayload.message = "Reports > Charging > XLS";
      auditPayload.action = "View";
      auditPayload.featureName = "Charging Report";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditLog(auditPayload);

      fetch(url, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(window.localStorage.getItem("jwToken")),
        },
      }) // FETCH BLOB FROM IT
        .then((response) => {
          response.blob();
        })
        .then((blob) => {})
        .catch((err) => {});
    } catch (error) {}
  };
  const onChangePDF = async () => {
    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "ChargingReport/ChargingreportPDF.pdf?startDate=" +
        payload.startDate +
        "&endDate=" +
        payload.endDate +
        "&search=" +
        payload.search +
        "&orgId=" +
        payload.orgId +
        "&vehicleId=" +
        payload.vehicleId +
        "&reselId=" +
        payload.reselId +
        "&regionId=" +
        payload.regionId +
        "&dealerId=" +
        payload.dealerId +
        "&custId=" +
        payload.custId +
        "&roleId=" +
        roleId;

      window.open(url);

      auditPayload.message = "Reports > Charging > PDF";
      auditPayload.action = "View";
      auditPayload.featureName = "Charging Report";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditLog(auditPayload);
    } catch (error) {}
  };
  //Search Text
  const handleSearch = async (searchText) => {
    payload.search = searchText;
    setNewPage(0);
    setRowsPerPage(25);
    if (payload.search.length >= 3) {
      getOverSpeed(payload);
    } else if (payload.search.length === 0) {
      getOverSpeed(payload);
    }

  };
  
  //Page Change
  const pageChange = (newPage, rowsPerPage) => {
    setRowsPerPage(rowsPerPage);
    payload.size = rowsPerPage;
    if(totalElements < rowsPerPage * newPage){
      setNewPage(0)
      payload.page = 0;
      getOverSpeed(payload);
    }else{
      setNewPage(newPage);
      payload.page = newPage;
      getOverSpeed(payload);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setResponseData([]);
      if (oversppedData.data !== null) {
        setResponseData(oversppedData.data.content);
        setTotalElements(oversppedData.data.totalElements);
        setTotalPages(oversppedData.data.totalPages);
      }
    }
  }, [isSuccess]);



  return (
    <StyledPageLayout sx={{ p: 0}}>
     
      <MouseOverPopover text={text} title={"Charging Report"} />
      <Box>

     
        <FilterDropdown getData={onSubmitForm} status={false} allowDays = {resellerId === 110224 ? 31 : 7} allowedMonth = {resellerId === 110224 ? 6 : 3} time = {true}  thisMonth={true} currentMonth={true} chargeTypeShow={resellerId === 110224 ? true : false} last6Month={true} last31={resellerId === 110224 ? true : false} />

        <StyledCardShadow sx={{ mt: 2 }}>
          <CardContent sx={{ py: 1 }}>
            <DataTable
            payload={payload}
            exportWithAll={false}
              isLoading={isLoading}
                columns={ resellerId === 114075 ?columns1
                  : resellerId === 114367 ?columns1
                  :columns}  
              initialState={initialState1}
              data={responseData}
              onChangeXcl={onChangeXcl}
              onChangePdf={onChangePDF}
              styles={{ maxWidth: "100vw" }}
              numberOfStickyColumns={1}
              includeSearchBox={true}
              handleChange={handleSearch}
              page={newPage}
              rowsPerPage={rowsPerPage}
              callbackChangePage={pageChange}
              totalElements={totalElements}
              totalPages={totalPages}
              includeExportButton={true}
            />
          </CardContent>
        </StyledCardShadow>
      </Box>
    </StyledPageLayout>
  );
};

export default ChargingReport;
